import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider
} from '@material-ui/core';

import * as commonService from '../../../../utils/CommonService.js';

const ConfirmDialog = () => {
  const defaultOptions = {
    open: false,
    data: { message: 'Are you Sure?',image:''},
    cancelText: 'Cancel',
    ConfirmText: 'Okay',
    onConfirm: () => {},
  };
  const [dialogOptions, setDialogOptions] = useState(defaultOptions);

  commonService.isDialogOpen.subscribe(data => {
    if (data && !dialogOptions.open) setDialogOptions(data);
    else if (!data && dialogOptions.open) setDialogOptions(defaultOptions);
  });

 const { open, data, cancelText, confirmText, onConfirm } = dialogOptions;

  const handleConfirm = (confirm) => {
    if (typeof onConfirm !== 'undefined') onConfirm(confirm);
  };

  const handleClose = () =>  {
    if (typeof onConfirm !== 'undefined') onConfirm(false);
  };
  return (
      <Dialog onClose={handleClose} aria-labelledby="common-dialog-title" open={open}>
        { data.title && <DialogTitle id="common-dialog-title">{data.title}</DialogTitle> }
        <DialogContent className="p-3 text-center" >{data.message}</DialogContent>
       {data.image?<DialogContent className="pt-1" > <div className='profile-header p-30'>
		  	<div className='user-info text-center'>
			
					<img
						className='profile-image avatar huge' style={{margin:"0px auto"}}
						src={data.image}
						alt='profile'
					/>
				
			</div>
		</div></DialogContent>:
        <Divider></Divider>}
        {
          open &&
          <DialogActions className="p-2" >
            <Button variant="outlined" size="small"  onClick={() => handleConfirm(false)} >{cancelText}</Button>
            <Button variant="contained" size="small" color="primary" className="btn-them-primary" onClick={() => handleConfirm(true)}>{confirmText}</Button>
          </DialogActions>
        }
      </Dialog>
  );
};

export default  ConfirmDialog;
