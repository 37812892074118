import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// ui components
import FormWrapper from '../shared/ui-components/form-wrapper';
import CommonForm from '../shared/ui-components/common-form';
import { fieldConfig } from './FieldConfig';
import * as userApi from './../../api';
// service
import * as commonService from '../../utils/CommonService.js';
const ForgotPassword = ({ history }) => {
  const [forgotPasswordForm, setLForgotPasswordForm] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    userApi.forgotpassword(forgotPasswordForm.value).then((resp) => {
      commonService.forSuccess('Send successfully', 'Success');
      history.push('/admin/forgotpassword');
    });
  };

  const setForm = (form) => {
    setLForgotPasswordForm(form);
  };

  return (
    <FormWrapper>
      <div className="fields lo_All">
        <h1>Reset password</h1>
        <h2>Please enter the email address you used when creating your account.</h2>
        <CommonForm setForm={setForm} fieldConfig={fieldConfig} handleSubmit={handleSubmit}></CommonForm>
        <div className="lo-W-All">
          <Link className=" underline" to="/login">
            ← Go back to sign-in
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
};

export default ForgotPassword;
