import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
	makeStyles,
	withStyles,
	GridList,
	GridListTile,
	Drawer,
	Button,
	Divider,
	TextField,
	Card,
	CardContent,
	CardHeader,
	FormGroup,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: '100%',
		height: 345,
	},
}));

const PostInfo = (props) => {
	const classes = useStyles(props);
	const tileData =
		props.userData.postImages?.length > 0
			? props.userData.postImages.map((profilePic) => {
					let rObj = {};
					rObj['img'] = profilePic;
					return rObj;
			  })
			: [];
	
	return (
		<>
		<div className="row">
		<div className="col-lg-6 col-md-6 col-sm-6">
		<Card className='profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>
							General Information
						</span>
					}
				></CardHeader>
				<CardContent className="height-scroll">
					<div>
					<div className='info-line'>
						<div className='title'>Title</div>
						<div className='info'>{props.userData?.title}</div>
					</div>
					</div>
					<div className='info-line'>
						<div className='title'>Post Description</div>
                        <div className='info'>{props.userData['postDescription']}</div>
						</div>
				
				</CardContent>
			</Card>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='mt-3 profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={<span className='title card-title'>Post Visibility & Tags</span>}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className='info-line'>
						<div className='title'>Post visible to</div>
						<div className='info  '>
							<span>
								{props.userData.postVisibility && props.userData.postVisibility.length
									? props.userData.postVisibility.map((data,index)=>
                                    <div key={index} style={{display:'inline'}}>{data}, </div>
                                    )
									: 'No information available yet'}
							</span>
						</div>
					</div>

					<div className='info-line'>
						<div className='title'>Tags</div>
						<div className='info'>
							<span>{props.userData.tags && props.userData.tags.length? props.userData.tags.map((data,index)=>
                                    <div key={index}  style={{display:'inline'}}>{data}, </div>
                                    )
									: 'No information available yet'}</span>
						</div>
					</div>
				</CardContent>
              
			</Card>
            </div>
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>Post Images</span>
					}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className={classes.root}>
						<GridList
							cellHeight={160}
							className={classes.gridList}
							cols={5}
						>
							{tileData.length && tileData.map((tile) => (
								<GridListTile
									key={tile.img}
									cols={tile.cols || 1}
								>
									<img src={tile.img} alt="" />
								</GridListTile>
							))}
						</GridList>
					</div>
				</CardContent>
			</Card>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='mt-3 profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={<span className='title card-title'>Comments</span>}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className='info-line'>
						<div className='title'>Commented By</div>
						<div className='info  '>
							<span>
								{props.userData.comments && props.userData.comments.length
									? props.userData.comments.map((data,index)=>
                                    <div key={index}>{data.comment}</div>
                                    )
									: 'No information available yet'}
							</span>
						</div>
					</div>
				</CardContent>
              
			</Card>
			</div>
			</div>
		</>
	);
};

export default withRouter(PostInfo);
