import React, {useState,useRef} from 'react';

import {
    Card,
    CardContent,
    TextField,
    Button,
    FormControl,
} from '@material-ui/core';
import * as moment from 'moment';

const Filter = ({
  resetPartnerForm,
    handleFilter,partnerCreatedOn
    }) => {

  const [filterForm, setFilterForm] = useState({
	text:'',createdAt:'', startDate:'',endDate:''
  });
  const  inputTo = useRef(null);
  const  inputFrom = useRef(null);
  // reset filter form and data
  const handleReset = () => {
    const data = {
      text:'',createdAt:'', startDate:'',endDate:''};
      inputTo.current.value = '';
    inputFrom.current.value = '';
    setFilterForm(data);
    resetPartnerForm(data);
  };
  // filter lead table according to filed value changes
  const handleChanges= (key, value) => {
    const data = {...filterForm,[key]: value};
    setFilterForm(data);
    handleFilter(data);
  };

  return (
      <div className="row mb-5">
        <div className="col-12">
          <Card>
            <CardContent>
              <form>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6">
                    <TextField
                        value={filterForm.text}
                        className="w-100 mt-3"
                        onChange={(e) => handleChanges('text', e.target.value)}
                        placeholder="Search" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                        <FormControl className="MuiInput-underline">
										{' '}
										<input type="date"
											placeholder='Date from' className="MuiInputBase-input MuiInput-input mt-3"
                      ref={inputTo}
                      selected={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'startDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											} 
										/>
                    </FormControl> 
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                    <FormControl className="MuiInput-underline">
										<input
                    type="date"
                    placeholder ='Date to' className="MuiInputBase-input MuiInput-input mt-3"
                    ref={inputFrom}
											selected={filterForm.endDate}
											//minDate={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'endDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											} 
										/>
									</FormControl>        
                         {/* <FormControl className='MuiInput-underline'>
										{' '}
                    <input type="date" onChange={(e) => handleChanges('createdAt', moment(e.target.value).format('DD/MM/YYYY'))} className="MuiInputBase-input MuiInput-input mt-3" />
									</FormControl> */}
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6">
                    <Button variant="contained"   color="default" className="btn-theme-primary mt-3 float-none"  onClick={() => handleReset()} >Reset</Button>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
  )
};
export default Filter;