import React, {useState,useRef} from 'react';

import {
    Card,
    CardContent,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@material-ui/core';
import * as moment from 'moment';
const Filter = ({
	resetReportFilterForm,
    handleFilter,
	reporttypeGroups,reportCreatedOn,reportStatusGroups
    }) => {
  const inputTo = useRef(null);
  const inputFrom = useRef(null);
  const [filterForm, setFilterForm] = useState({
	text:'',
	ReportedOn: '',
  createdAt:'',reportStatus:'', startDate:'',endDate:''
  });
  // reset filter form and data
  const handleReset = () => {
    const data = {
      text:'',
      ReportedOn: '',createdAt:'',reportStatus:'', startDate:'',endDate:''};
    setFilterForm(data);
    inputTo.current.value = '';
    inputFrom.current.value = '';
	resetReportFilterForm(data);
  };
  // filter lead table according to filed value changes
  const handleChanges= (key, value) => {
    const data = {...filterForm,[key]: value};
    setFilterForm(data);
    handleFilter(data);
  };

  return (
      <div className="row mb-5">
        <div className="col-12">
          <Card>
            <CardContent>
              <form>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <TextField
                        value={filterForm.text}
                        className="w-100 mt-3"
                        onChange={(e) => handleChanges('text', e.target.value)}
                        placeholder="Search" />
                  </div>
                  
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <FormControl  >
                      <InputLabel id="demo-simple-select-label"> Reported On </InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterForm.ReportedOn}
                          onChange={(e) => handleChanges('ReportedOn', e.target.value)}
                          >
                        {
                          reporttypeGroups.map((data, index) => <MenuItem key={index} value={data}>{data}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                    <FormControl className="MuiInput-underline">
										{' '}
                    <input type="date"
											placeholder='Date from' className="MuiInputBase-input MuiInput-input mt-3"
                      selected={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
                      ref={inputTo}
											onChange={(e) =>
												handleChanges(
													'startDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											} 
										/>
                    </FormControl>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                    <FormControl className="MuiInput-underline">
										<input
                    type="date"
                    placeholder ='Date to' className="MuiInputBase-input MuiInput-input mt-3"
											selected={filterForm.endDate}
                      ref={inputFrom}
											//minDate={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'endDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											} 
										/>
                    {/* <FormControl className="MuiInput-underline" >
										{' '}
                    <input type="date" onChange={(e) => handleChanges('createdAt', moment(e.target.value).format('DD/MM/YYYY'))} className="MuiInputBase-input MuiInput-input mt-3" />
									</FormControl> */}
									</FormControl>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                  <FormControl>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <Select
                          value={filterForm.reportStatus}
                          onChange={(e) => handleChanges('reportStatus', e.target.value)}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          >
                        {
                          reportStatusGroups.map((active, index) => <MenuItem key={index} value={active}>{active}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-6">
                    <Button variant="contained"   color="default" className="btn-theme-primary float-none mt-3"  onClick={() => handleReset()} >Reset</Button>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
  )
};
export default Filter;