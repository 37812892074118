import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';
import ArticleInfo from './articleInfo';
import ArticleQst from './ArticleQst';

const articleTabs = (props) => {
  // custom tab data
  const labelsData = ['Article Info', 'Question & Answer'];
  const tabsData = {
    0: <ArticleInfo userData={props.userData}></ArticleInfo>,
    1: <ArticleQst userData={props.userData}></ArticleQst>,
  };

  return <TabsUI labels={labelsData} tabs={tabsData} />;
};

export default articleTabs;
