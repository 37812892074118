import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
  makeStyles,
  withStyles,
  GridList,
  GridListTile,
  Drawer,
  Button,
  Divider,
  TextField,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';
import AppContext from './../../context';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const ArticleInfo = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const tileData =
    props.userData.mediaLinks?.length > 0
      ? props.userData.mediaLinks.map((profilePic) => {
          let rObj = [];
          rObj = profilePic;
          return rObj;
        })
      : [];
  return (
    <>
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Article Information</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div>
            <div className="info-line">
              <div className="title">Title</div>
              <div className="info">{props.userData?.title}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="title">Article read by</div>
            <div className="info">
              {props.userData['readBy'] && props.userData['readBy'].length
                ? props.userData['readBy'].map((x) => {
                    const found = userResponse.find((data) => data._id === x);
                    if (found) {
                      return  <div style={{display:'inline'}}> {found.uniqueName},</div>;
                    }
                  })
                : ''}
            </div>
          </div>
          <div className="info-line">
            <div className="title">Tags</div>
            <div className="info">
              {props.userData.tags && props.userData.tags.length
                ? props.userData.tags.map((x) => {
                    return x;
                  })
                : 'data not find'}
            </div>
          </div>
        </CardContent>
      </Card>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader className="card-header" title={<span className="title card-title">Media</span>}></CardHeader>
        <CardContent className="height-scroll">
          <div className={classes.root}>
            <GridList cellHeight={160} className={classes.gridList} cols={5}>
              {tileData.map((tile, index) => (
                <GridListTile key={index} cols={tile.cols || 1}>
                  {tile.split('.').reverse()[0] == 'mp4' ? (
                    <ReactPlayer className="videoFrame" url={tile} playing controls alt={tile.title} />
                  ) : (
                    <img src={tile} alt={tile.title} />
                  )}
                </GridListTile>
              ))}
            </GridList>
          </div>
        </CardContent>
      </Card>
      </div>
      </div>
    </>
  );
};

export default withRouter(ArticleInfo);
