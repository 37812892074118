import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    Button,
    IconButton,
    Divider,
    Menu,
    MenuItem,
    Select,
    InputLabel,
    FormControl

} from '@material-ui/core';
// material icons
import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';
import DialpadIcon from '@material-ui/icons/Dialpad';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import MoreHoriztIcon from '@material-ui/icons/MoreHoriz';
// custom tabs ui
import TabsUI from '../shared/ui-components/TabsUI.js'
// ui components
import CommonTable from './CommonTable.js';

const OpportunityCard = () => {
  // opportunity card dropdown control
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // show card dropdown item
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  // close card dropdown item
  const handleClose = () => {
    setAnchorEl(null);
  };

  // custom tabs options
  const tabsLabel = ['Clients', 'Description'];
  const tabsData = {
    0: <CommonTable></CommonTable>,
    1: <p className="mt-3 p-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
      when an unknown printer took a galley of type and scrambled it to make a type specimen book.
      It has survived not only five centuries, but also the leap into electronic typesetting,
      remaining essentially unchanged. It was popularised in the 1960s with the release of
      Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
      like Aldus PageMaker including versions of Lorem Ipsum</p>
  };

  return (
      <Card className="pt-1 opportunity-card mt-3">
        <div className="row pb-1 pt-1">
          <div className="col-6">
            <h6 className="mb-0 ml-2">Glenna Reichert</h6>
            <small className="ml-2">Client</small>
          </div>
          <div className="col-6 text-right">
            <span className="mat-button-wrapper profile-icon pr-2"><img src={require("../../assets/images/avatar/Avatar1.png")} alt="av1" /></span>
            <span className="p-2 align-middle"><CallIcon></CallIcon></span>
            <span className="p-2 align-middle"><MessageIcon></MessageIcon></span>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                >
              <MoreHoriztIcon />
            </IconButton>
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                >
              <MenuItem  className="card-menu" onClick={handleClose}>
                <DialpadIcon className="dropdown-icon mr-3"></DialpadIcon> Redial
              </MenuItem>
              <MenuItem  className="card-menu" onClick={handleClose}>
                <VoicemailIcon className="dropdown-icon mr-3"></VoicemailIcon> Check voice mail
              </MenuItem>
              <MenuItem  className="card-menu" onClick={handleClose}>
                <NotificationsOffIcon className="dropdown-icon mr-3"></NotificationsOffIcon> Disable alerts
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Divider></Divider>
        <CardContent className="mb-0 pl-3 pr-3 p-0">
          <TabsUI labels={tabsLabel} tabs={tabsData} />
        </CardContent>
        <CardActions>
          <div className="row w-100" >
            <div className="ml-2 col-4">
              <FormControl className="w-100">
                <InputLabel>Choose option</InputLabel>
                <Select value="first">
                  <MenuItem value="first">
                    David deo
                  </MenuItem>
                  <MenuItem value="second">
                    John
                  </MenuItem>
                  <MenuItem value="third">
                    Chris
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-7 text-right pr-0">
              <Button variant="contained"  className="h-75 opportunity-btn btn-theme-primary">Check Opportunities</Button>
            </div>
          </div>
        </CardActions>
      </Card>
  )
};

export default OpportunityCard;