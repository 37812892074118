// public routes
import Login from '../containers/login';
import Register from '../containers/register';
import ForgotPassword from '../components/forgot-password/ForgotPassword.js';

//private routes
import Dashboard from '../containers/dashboard';
import Profile from '../components/profile/ProfileTabs';
import Opportunities from '../containers/opportunities';
import Client from '../containers/clients/client-list';
import UserProfile from '../containers/user-profile/';
import PostProfile from '../containers/post-profile';
import ReportProfile from '../containers/report-profile';
import CommunityProfile from '../containers/community-profile';
import ArticlesProfile from '../containers/article-profile';
import EventProfile from '../containers/event-profile';
import ArticleProfile from '../containers/article-profile';
import PartnerProfile from '../containers/partner-profile';
import InfluncerProfile from '../containers/influncer-profile';
import NewClient from '../containers/clients/new-client';
import Users from '../containers/users';
import Posts from '../containers/posts';
import Articles from '../containers/article';
import Partners from '../containers/partner';
import Events from '../containers/events';
import Reports from '../containers/reports';
import Community from '../containers/community';
import ReferralCode from '../containers/ReferralCode';
import Influncer from '../containers/Influncer';
import Bug from '../containers/bugs';
import CommunityGuideline from '../containers/communityGuideline';

export const publicRoutes = [
	{
		key: 'login',
		path: '/admin/login',
		component: Login,
		exact: true,
	},
	{
		key: 'register',
		path: '/admin/register',
		component: Register,
		exact: true,
	},
	{
		key: 'forgot_password',
		path: '/admin/forgotPassword',
		component: ForgotPassword,
		exact: true,
	},
];

export const privateRoutes = [
	{
		key: 'dashboard',
		path: '/admin/dashboard',
		component: Dashboard,
		exact: true,
	},
	{
		key: 'profile',
		path: '/admin/profile',
		component: Profile,
		exact: true,
	},
	{
		key: 'posts',
		path: '/admin/posts',
		component: Posts,
		exact: true,
	},
	{
		key: 'reports',
		path: '/admin/reports',
		component: Reports,
		exact: true,
	},
	
	{
		key: 'events',
		path: '/admin/events',
		component: Events,
		exact: true,
	},
	{
		key: 'opportunities',
		path: '/admin/opportunities',
		component: Opportunities,
		exact: true,
	},
	{
		key: 'Influncer',
		path: '/admin/Influncer',
		component: Influncer,
		exact: true,
	},
	{
		key: 'Bug',
		path: '/admin/Bug',
		component: Bug,
		exact: true,
	},
	//{
	//  key: "clients",
	//  path: "/users",
	//  component: Client,
	//  exact: true
	//},
	{
		key: 'userProfile',
		path: '/admin/user/:id',
		component: UserProfile,
		exact: true,
	},
	{
		key: 'InfluncerProfile',
		path: '/admin/Influncer/:id',
		component: InfluncerProfile,
		exact: true,
	},
	{
		key: 'userProfile',
		path: '/admin/community/:id',
		component:CommunityProfile,
		exact: true,
	},
	{
		key: 'userProfile',
		path: '/admin/post/:id',
		component:PostProfile,
		exact: true,
	},
	{
		key: 'userProfile',
		path: '/admin/event/:id',
		component:EventProfile,
		exact: true,
	},
	{
		key: 'userProfile',
		path: '/admin/report/:id',
		component:ReportProfile,
		exact: true,
	},
	{
		key: 'articleProfile',
		path: '/admin/article/:id',
		component:ArticleProfile,
		exact: true,
	},
	{
		key: 'partnerProfile',
		path: '/admin/partner/:id',
		component:PartnerProfile,
		exact: true,
	},
	{
		key: 'articlesProfile',
		path: '/admin/article/:id',
		component: ArticlesProfile,
		exact: true,
	},
	{
		key: 'clients',
		path: '/admin/clients/new',
		component: NewClient,
		exact: true,
	},
	{
		key: 'users',
		path: '/admin/users',
		component:Users,
		exact: true,
	},
	{
		key: 'community',
		path: '/admin/community',
		component:Community,
		exact: true,
	},
	{
		key: 'articles',
		path: '/admin/articles',
		component:Articles,
		exact: true,
	},
	,
	{
		key: 'partners',
		path: '/admin/partners',
		component: Partners,
		exact: true,
	},
	{
		key: 'referral',
		path: '/admin/ReferralCode',
		component:ReferralCode,
		exact: true,
	},
	{
		key: 'guideline',
		path: '/admin/Guideline',
		component:CommunityGuideline,
		exact: true,
	},
];
