import React, { useState, useEffect, useContext } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as commonService from '../../utils/CommonService.js';
import * as userApi from '../../api';
import AppContext from '../../context';
import Filter from './Filter.js';
import LeadForm from './UserForm.js';
import * as moment from 'moment';

const useStyles = makeStyles({
  leadTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  {id: 'sno', label: 'S.No' },
  { id: 'fullname', label: 'Name' },
  { id: 'uniqueName', label: 'Profile Name' },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    align: 'right',
  },
  {
    id: 'gender',
    label: 'Gender',
    minWidth: 25,
    align: 'right',
  },
  {
    id: 'location',
    label: 'Location',
    align: 'right',
  },
  {
    id: 'verificationSelfie',
    label: 'Selfie Verification',
    align: 'right',
  },

  {
    id: 'isVerified',
    label: 'Account Verified',
    sortable: true,
    align: 'right',
  },
  {
    id: 'isActive',
    label: 'Status',
    align: 'right',
  },
  {
    id: 'createdAt',
    label: 'Created on',
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'right',
  },
];

function createData(
  sno,
  fullname,
  uniqueName,
  email,
  gender,
  location,
  verificationSelfie,
  isVerified,
  isActive,
  createdAt,
  action
) {
  return {
    sno,
    fullname,
    uniqueName,
    email,
    gender,
    location,
    verificationSelfie,
    isVerified,
    isActive,
    createdAt,
    action,
  };
}
const rows = [];

const UserTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [userData, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [responseVerify, setResponseVerify] = useState();
  const [searchClick, setSearchClick] = useState(false);

  useEffect(() => {
    let x = [];
    userResponse.map((user, index) => {
      x.push(
        createData(
          index + 1,
          user.fullName,
          user.uniqueName,
          user.email,
          user.sex !== null ? user.sex.sex : '',
          user.location,
          user.verificationSelfie
            ? user.verificationSelfie.isVerified === false
              ? 'Pending'
              : 'Accepted'
            : 'Not Available',
          user.isVerified === true ? 'Yes' : 'No',
          user.status === true || user.isActive === true          ? 
             'Active'
          : 
              'Inactive'
          ,
          moment(user.createdAt).format('DD/MM/YYYY'),
          <>
            <VisibilityIcon
              style={{ cursor: 'pointer', align: 'center' }}
              onClick={() => history.push(`/admin/user/${user._id}`)}
            />
          </>
        )
      );
    });
    setRowData(x);
    setUsersData(x);
  }, [userResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleVerifyApi = (confirm) => {
    if (responseVerify && responseVerify !== undefined) {
      const found = userResponse.find((x) => {
        if (x.email === responseVerify.email) {
          return x;
        }
      });
      if (confirm === true) {
        userApi.postSelfieVerification(found.email, true, found.verificationSelfie.selfieUrl).then((resp) => {
          if (resp) {
            commonService.isLoading.onNext(true);
            window.location.reload(false);
            commonService.forSuccess('Accepted', 'Success');
            setTimeout(() => commonService.isLoading.onNext(false), 500);
          }
        });
      }
    }
    commonService.isDialogOpen.onNext(false);
  };

  const handleVerifyConfrm = (found, confirm) => {
    if (confirm === true) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure wanted to confirm this selfie!',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: handleVerifyApi,
      });
    } else if (confirm === false) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure wanted to Reject this selfie!',
        },
        cancelText: 'No',
        confirmText: 'yes',
        onConfirm: (value) => handleVerifyApiReject(found, value),
      });
    }
  };

  const selfieVerification = (event) => {
    const found = userResponse.find((x) => {
      if (x.email === event.email) {
        return x;
      }
    });
    setResponseVerify(found);
    if (found.verificationSelfie.isVerified === false) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Selfie Verification',
          image: found.verificationSelfie.selfieUrl,
        },
        cancelText: 'Reject',
        confirmText: 'Accept',
        onConfirm: (value) => handleVerifyConfrm(found, value),
      });
    }
  };

  const handleVerifyApiReject = (response, confirm) => {
    if (response && response !== undefined) {
      const found = userResponse.find((x) => {
        if (x.email === response.email) {
          return x;
        }
      });
      if (confirm === true) {
        userApi.postSelfieVerification(found.email, false, found.verificationSelfie.selfieUrl).then((resp) => {
          if (resp) {
            commonService.isLoading.onNext(true);
            window.location.reload(false);
            commonService.forError('Selfie Rejected');
            setTimeout(() => commonService.isLoading.onNext(false), 500);
          }
        });
      }
    }
    commonService.isDialogOpen.onNext(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // custom filter by text , company and gender
  const handleFilter = (filterForm) => {
    const data = userData.filter((row) => {
    
      if (filterForm['isVerified'] && row['isVerified'] !== filterForm['isVerified']) return false;
      if (filterForm['isActive'] && row['isActive'] !== filterForm['isActive']) return false;
      if (filterForm['gender'] && row['gender'] !== filterForm['gender']) return false;
      if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;
      if(filterForm['startDate'] && filterForm['endDate']){
      var d1 = filterForm['startDate'].split("/");
      var d2 = filterForm['endDate'].split("/");
      var c = row['createdAt'].split("/");
      var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
      var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
      var check = new Date(c[2], parseInt(c[1])-1, c[0]);
      if(check >= from && check <= to){ return true }else{  return false };
      }
      if (filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
     
      return true;
    });

    setRowData(data);
  };

  const resetLeadFilterForm = () => {
    setRowData(userData);
  };

  const handleSearch = () => {
    setSearchClick(true);
  };

  const ActiveHandle = (usersData) => {
    const found = userResponse.find((x) => {
      if (x.email === usersData.email) {
        return x;
      }
    });
    if (found) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure you want to change the status ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => handleActiveConfrm(found._id, value),
      });
    }
  };

  const handleActiveConfrm = (usersData, value) => {
    if (value === true) {
      const ApprovedStatus = true;
      userApi.postUserStatus(usersData, ApprovedStatus).then((data) => {
        if (data) {
          commonService.isLoading.onNext(true);
          window.location.reload();
          commonService.forSuccess('successfully Approved!', 'Success');
          setTimeout(() => commonService.isLoading.onNext(false), 500);
          window.location.reload();
        }
      });
    } else if (value === false) {
      window.location.reload();
      commonService.isDialogOpen.onNext(false);
    }
  };

  const InactiveHandle = (usersData) => {
    const found = userResponse.find((x) => {
      if (x.email === usersData.email) {
        return x;
      }
    });
    if (found) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure you want to change the status ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => handleRejectConfrm(found._id, value),
      });
    }
  };

  const handleRejectConfrm = (usersData, value) => {
    if (value === true) {
      const RejectedStatus = false;
      userApi.postUserStatus(usersData, RejectedStatus).then((data) => {
        if (data) {
          commonService.isLoading.onNext(true);
          commonService.forWarning('Rejected!', 'Warning');
          window.location.reload();
          setTimeout(() => commonService.isLoading.onNext(false), 500);
        }
      });
    } else if (value === false) {
      window.location.reload();
      commonService.isDialogOpen.onNext(false);
    }
    commonService.isDialogOpen.onNext(false);
  };

  return (
    <div className="p-3 ">
      <div>
        <div>
          <h2>
            Users{' '}
            <div className="col-3 float-right">
              {searchClick === false ? (
                <Button
                  variant="contained"
                  color="default"
                  className="btn-theme-primary"
                  onClick={() => handleSearch()}
                >
                  Show Filter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="default"
                  className="btn-theme-primary"
                  onClick={() => setSearchClick(false)}
                >
                  Hide Filter
                </Button>
              )}
            </div>
          </h2>
        </div>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetLeadFilterForm={resetLeadFilterForm}
          leadGenderGroups={[...new Set(userData.map((lead) => lead.gender))]}
          leadActiveGroups={['Active','Inactive']}
          leadVerifiedGroups={[...new Set(userData.map((lead) => lead.isVerified))]}
          leadCreatedOn={[...new Set(userData.map((lead) => lead.createdAt))]}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.leadTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">{
                            }
                              {column.format && typeof value === 'boolean' ? (
                                column.format(value)
                              ) : value === 'Pending' || value === 'Accepted' ? (
                                value === 'Pending' ? (
                                  <Button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-theme-primary"
                                    onClick={() => selfieVerification(row)}
                                  >
                                    {value}
                                  </Button>
                                ) : (
                                  'Accepted'
                                )
                              ) : value === 'Active' || value === 'Inactive' ? value === 'Active'? (
                                <Button style={{ background: '#4dff88' }} onClick={() => InactiveHandle(row)}>
                                    {value}
                                  </Button>
                                ) : (
                                  <Button style={{ background: '#ff4d4d' }} onClick={() => ActiveHandle(row)}>
                                    {value}
                                  </Button>
                                )
                              :
                                value
                              }
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25, 50, 100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserTable);
