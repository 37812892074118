import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const InfluncerInfo = (props) => {
  const classes = useStyles(props);

  return (
    <>
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Influencer information</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div>
            <div className="info-line">
              <div className="title">Reason</div>
              <div className="info">{props.userData?.reason}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="title">Reference</div>
            <div className="info">{props.userData?.reference}</div>
          </div>
        </CardContent>
      </Card>
    </div>
    </div>
    </>
  );
};

export default withRouter(InfluncerInfo);
