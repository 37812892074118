import React, { useState, useEffect, useContext } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  Button,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as userApi from '../../api';
import AppContext from '../../context';
import Filter from './influncerFilter';
import * as moment from 'moment';
import * as commonService from '../../utils/CommonService.js';
const useStyles = makeStyles({
  EventTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'userId', label: 'User name' },
  {
    id: 'Status',
    label: 'Influencer Status',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'createdAt',
    label: 'Created on',
    sortable: true,
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'right',
  },
];

function createData(sno,userId,Status, status, createdAt, action) {
  return {
    sno,
    userId,Status,
    status,
    createdAt,
    action,
  };
}
const rows = [];

const InfluncerTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [influncerData, setInfluncerData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userData, setUsersData] = useState([]);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [searchClick, setSearchClick] = useState(false);

  useEffect(() => {
    let x = [];
    userApi.getInfluncerRecords().then((data) => {
      if (userResponse && userResponse.length) {
        for (let i = 0; i < userResponse.length; i++) {
          data.data.map((data, index) => {
            if (userResponse[i]._id === data.userId) {
              x.push(
                createData(
                  index + 1,
                  userResponse[i].uniqueName ? userResponse[i].uniqueName : '-',
                  data.status,
                  data.status === 'Pending'? (<div><Button style={{ background: '#4dff88' }} onClick={() => ActiveHandle(data)}>
                  Approved
               </Button>/<Button style={{ background: '#ff4d4d' }} onClick={() => InActiveHandle(data)} >
                    Reject
                  </Button></div>): data.status === 'Approved'?<Button style={{ background: '#4dff88' }} onClick={() => InActiveHandle(data)}>Approved</Button> : <Button style={{ background: '#ff4d4d' }}onClick={() => ActiveHandle(data)}  >
                    Reject
                  </Button>,  
                  moment(data.createdAt).format('DD/MM/YYYY'),
                  <>
                    <VisibilityIcon
                      style={{ cursor: 'pointer', align: 'center' }}
                      onClick={() => history.push(`/admin/influncer/${data._id}`)}
                    />
                  </>
                )
              );
            }
          });
        }
      }
      setRowData(x);
      setInfluncerData(x);
    });
  }, [userResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = async (filterForm) => {
    const data = influncerData.filter((row) => {
      if (filterForm['status'] && row['Status'] !== filterForm['status']) return false;
      // if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;
      if (filterForm['startDate'] && filterForm['endDate']) {
        var d1 = filterForm['startDate'].split('/');
        var d2 = filterForm['endDate'].split('/');
        var c = row['createdAt'].split('/');
        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
        if (check >= from && check <= to) {
          return true;
        } else {
          return false;
        }
      }
      if (
        filterForm['text'].toLowerCase() &&
        !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())
      )
        return false;
      return true;
    });
    setRowData(data);
  };
  const resetInfluncerForm = () => {
    setRowData(influncerData);
  };

  const handleSearch = () => {
    setSearchClick(true);
  };
  const ActiveHandle = (usersData) => {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure you want to change the status ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => ApprovedHandle(usersData, value),
      });
  };

  const InActiveHandle = (usersData) => {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure you want to change the status ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => RejectedHandle(usersData, value),
      });
  };

  const ApprovedHandle = (usersData,value) => {
    if (value === true) {
    const ApprovedStatus = 'Approved';
    userApi.postInfluncerStatus(usersData._id, usersData.userId, ApprovedStatus).then((data) => {
      if (data) {
        commonService.isLoading.onNext(true);
        commonService.forSuccess('successfully Approved!', 'Success');
        setTimeout(() => commonService.isLoading.onNext(false), 500);
      }
    });
  }else if (value === false) {
    window.location.reload();
    commonService.isDialogOpen.onNext(false);
  }
  };

  const RejectedHandle = (usersData,value) => {
    if (value === true) {
    const RejectedStatus = 'Rejected';
    userApi.postInfluncerStatus(usersData._id, usersData.userId, RejectedStatus).then((data) => {
      if (data) {
        commonService.isLoading.onNext(true);
        commonService.forWarning('Rejected!', 'Warning');
        setTimeout(() => commonService.isLoading.onNext(false), 500);
      }
    });
  }else if (value === false) {
    window.location.reload();
    commonService.isDialogOpen.onNext(false);
  }
  };
  return (
    <div className="p-3 ">
      <div>
        <h2>
          Influencer Management{' '}
          <div className="col-3 float-right">
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                Show Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetInfluncerForm={resetInfluncerForm}
          // statusGroups={[...new Set(influncerData.map((data) => data.status))]}
          statusGroups={['Approved', 'Rejected', 'Pending']}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.EventTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">
                              {column.format && typeof value === 'boolean' ? (
                                column.format(value)
                              ): (
                                value
                              )}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25, 50, 100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InfluncerTable);
