import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';
import PostInfo from './PostInfo';

 const ProfileTabs = (props) => {
   // custom tab data
   const labelsData = [
     'Post Info',
   ];
   const tabsData = {
      0: <PostInfo userData={ props.userData}></PostInfo>,
   };

  return (
      <TabsUI labels={labelsData} tabs={tabsData} />
  )
};

export default ProfileTabs;