import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core';

const PartnerProfile= (props) => {

    return (
		<>
		<div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
		<Card className='profile-box info-box general'>
			 <CardHeader
				 className='card-header'
				 title={
					 <span className='title card-title'>
					 Partner Profile
					 </span>
				 }
			 ></CardHeader>
			 <div className="height-scroll">
		 {props.userData.partnerProfiles && props.userData.partnerProfiles.length?props.userData.partnerProfiles.map((data)=>
			 <CardContent className="row info-line">
				 <div className="col-md-3"><img
						className='profile-image avatar huge'
						 src={data.image}
						 alt='profile'
						 />
				 </div>
				 <div className='col-md-9'>
				 <div className='py-1'>
					 <div className='title float-left'>Name</div>
					 <div className='info float-left pl-2'>{data.name}</div>
					 <div className='clearfix'></div>
					 </div>
					 <div className='py-1'>
					 <div className='title float-left'>Open To</div>
					 {data.openTo && data.openTo.length ? data.openTo.map((data)=><div className='info float-left pl-2'>{data},</div>) :'no data'}
					 <div className='clearfix'></div>
					 </div>
					 <div className='py-1'>
					 <div className='title float-left'>Open To Meet</div>
					 {data.openToMeet && data.openToMeet.length ? data.openToMeet.map((data)=><div className='info float-left pl-2'>{data},</div>) :'no data'}
					 <div className='clearfix'></div>
					 </div>
					 <div className='py-1'>
					 <div className='title float-left'>Relationship Preference</div>
					 {data.relationshipPreference && data.relationshipPreference.length ? data.relationshipPreference.map((data)=><div className='info float-left pl-2'>{data},</div>) :'no data'}
					 <div className='clearfix'></div>
					 </div>
					 </div>
			 </CardContent>):<CardContent>No information available yet</CardContent>}
			 </div>
		 </Card>
		 </div>
		 </div>
 </>
    )
};

export default PartnerProfile;