import React, { useState, useEffect, useContext } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as userApi from './../../api';
import AppContext from './../../context';
import Filter from './bugsFilter';
import * as moment from 'moment';
import * as commonService from '../../utils/CommonService.js';
const useStyles = makeStyles({
  EventTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'type', label: 'Type' },
  { id: 'title', label: 'Title' },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'bugsStatus',
    label: 'Status',
  },
  {
    id: 'tags',
    label: 'Tags',
  },
  {
    id: 'userId',
    label: 'Bugs created by',
  },
  {
    id: 'featureDetail',
    label: 'Feature Detail',
  },
  {
    id: 'createdAt',
    label: 'Created on',
  },

  // {
  // 	id: 'action',
  // 	label: 'Action',
  // 	align: 'right',
  // },
];

function createData(
  sno,
  type,
  title,
  description,
  bugsStatus,
  tags,
  userId,
  featureDetail,
  createdAt
  // action
) {
  return {
    sno,
    type,
    title,
    description,
    bugsStatus,
    tags,
    userId,
    featureDetail,
    createdAt,
    // action
  };
}
const rows = [];

const BugsTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [bugsData, setBugsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [bugData, setBugData] = useState([]);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [searchClick, setSearchClick] = useState(false);

  useEffect(() => {
    let x = [];
    userApi.getAllBugs().then((data) => {
      setBugData(data.data);
      data.data.map((bug, index) => {
        x.push(
          createData(
            index + 1,
            bug.type,
            bug.title,
            bug.description,
            bug.bugsStatus === 'Pending' || bug.status === 'Active' ? (
             'Pending'
            ) : (
                'Resolved'
            ),
            bug.tags.join(', '),
           bug.userId.uniqueName,
            bug.featureDetail,
            moment(bug.createdAt).format('DD/MM/YYYY')
            // 	<>
            // 	<VisibilityIcon
            // 		style={{ cursor: 'pointer', align: 'center' }}
            // 		onClick={() =>
            // 			history.push(`/admin/bugs/${bug._id}`)
            // 		}
            // 	/>
            // </>,
          )
        );
      });

      setRowData(x);
      setBugsData(x);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = async (filterForm) => {
    const data = bugsData.filter((row) => {
      if (filterForm['bugsStatus'] && row['bugsStatus'] !== filterForm['bugsStatus']) return false;
      if (filterForm['type'] && row['type'] !== filterForm['type']) return false;
      // if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;
      if(filterForm['startDate'] && filterForm['endDate']){
        var d1 = filterForm['startDate'].split("/");
        var d2 = filterForm['endDate'].split("/");
        var c = row['createdAt'].split("/");
        var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
        var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
        var check = new Date(c[2], parseInt(c[1])-1, c[0]);
        if(check >= from && check <= to){ return true }else{  return false };
        }
        if (filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
      return true;
    });
    setRowData(data);
  };
  const resetBugsForm = () => {
    setRowData(bugsData);
  };

  const handleSearch = () => {
    setSearchClick(true);
  };
  const ActiveHandle = (usersData) => {
    const found = bugData.find((x,index) => index === usersData);
    if (found) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Is this Bug Resolved ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => handleActiveConfrm(found._id, value),
      });
    }
  };

  const handleActiveConfrm = (usersData, value) => {
    if (value === true) {
      const ApprovedStatus = 'Resolved';
      userApi.postBugsStatus(usersData, ApprovedStatus).then((data) => {
        if (data) {
          commonService.isLoading.onNext(true);
          window.location.reload();
          commonService.forSuccess('successfully!', 'Success');
          setTimeout(() => commonService.isLoading.onNext(false), 500);
        }
      });
    } else if (value === false) {
      window.location.reload();
      commonService.isDialogOpen.onNext(false);
    }
  };

  const InactiveHandle = (usersData) => {
    const found = bugData.find((x,index) => index === usersData);
    if ( found ) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Is this Bug still pending ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => handleRejectConfrm( found._id , value),
      });
    }
  };

  const handleRejectConfrm = (usersData, value) => {
    if (value === true) {
      const RejectedStatus = 'Pending';
      userApi.postBugsStatus(usersData, RejectedStatus).then((data) => {
        if (data) {
           commonService.isLoading.onNext(true);
           commonService.forSuccess('successfully!', 'Success');
           window.location.reload();
          setTimeout(() => commonService.isLoading.onNext(false), 500);
        }
      });
    } else if (value === false) {
      // window.location.reload();
      commonService.isDialogOpen.onNext(false);
    }
    commonService.isDialogOpen.onNext(false);
  };

  return (
    <div className="p-3 ">
      <div>
        <h2>
        Bugs & Features{' '}
          <div className="col-3 float-right">
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                Show Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetBugsForm={resetBugsForm}
          statusGroups={['Pending','Resolved']}
          typeGroups={['Bug','Feature']}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.EventTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="text-left"
                              style={{ width: '45px' }}
                            >
                              {column.format && typeof value === 'boolean' ? column.format(value) : value === 'Pending' || value === 'Resolved' ? value === 'Pending'? (
                                <Button style={{ background: 'Yellow' }} onClick={() => ActiveHandle(index)}>
                                {value}
                                </Button>
                                ) : (
                                  <Button style={{ background: '#4dff88' }} onClick={() => InactiveHandle(index)}>
                                 {value}
                                </Button>
                                )
                              :
                                value
                              }
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25, 50, 100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BugsTable);
