import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
// user api
import * as userApi from '../../api';
// service
import * as commonService from '../../utils/CommonService.js';
// ui components
import FormWrapper from '../../components/shared/ui-components/form-wrapper';
import CommonForm from '../../components/shared/ui-components/common-form';
import { FieldConfig } from './FieldConfig.js';
import AppContext from './../../context';
// Field config to configure form

const LoginForm = ({ history }) => {
  const [loginForm, setLoginForm] = useState({});
  const { adminProfile, setAdminProfile } = useContext(AppContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    userApi.login(loginForm.value).then((resp) => {
      commonService.forSuccess('Signed in successfully', 'Success');
      localStorage['authToken'] = resp.data.token;
      const userobj = { email: resp.data.email, firstName: resp.data.firstName, lastName: resp.data.lastName };
      window.localStorage.setItem('adminDetail', JSON.stringify(userobj));
      history.push('/admin/dashboard');
    });
  };

  const setForm = (form) => {
    setLoginForm(form);
  };

  return (
    <FormWrapper>
      <div className="fields lo_All">
        <h1>Sign In</h1>
        {/* <h2>
					Need a account?
					<Link className='underline' to='/register'>
						Sign Up
					</Link>
				</h2> */}
        <CommonForm setForm={setForm} fieldConfig={FieldConfig} handleSubmit={handleSubmit}></CommonForm>
        <div className="lo-W-All">
          <Link className=" underline" to="/admin/forgotPassword">
            Forgot your password?
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
};

export default LoginForm;
