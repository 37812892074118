import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Drawer,
	Button,
	Divider,
	TextField,
	FormGroup,
} from '@material-ui/core';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';
import InfluncerTabs from '../../components/influncer-profile/InfluncerTabs';
import './profile.scss';
import loader from 'sass-loader';

const InfluncerProfile = (props) => {
	const [usersData, setUsersData] = useState([]);

	const toggelActive = (confirm) => {
		if (confirm) {
			userApi.toggelActive(props.match.params.id).then((data) => {
				setUsersData(data.data);
			});
		}
		commonService.isDialogOpen.onNext(false);
	};
	const handelActive = (e, status) => {
		e.preventDefault();
		commonService.isDialogOpen.onNext({
			open: true,
			data: {
				message: `Are you sure you want ${status} user ?`,
			},
			cancelText: 'Cancel',
			confirmText: 'Okay',
			onConfirm: toggelActive,
		});
	};
	useEffect(() => {
		// Update the document title using the browser API
		userApi.getInfluncerById(props.match.params.id).then((data) => {
			setUsersData(data.data);
		});
	}, []);

	// const ApprovedHandle=()=>{
	// 	const ApprovedStatus='Approved';
	// 	userApi.postInfluncerStatus(usersData._id,usersData.userId,ApprovedStatus).then((data) => {	
	// 		if(data){
	// 			commonService.isLoading.onNext(true);
	// 			commonService.forSuccess('successfully Approved!', 'Success');
	// 			setTimeout(() => commonService.isLoading.onNext(false), 500);
			
	// 		}
	// 	});
	// }

	// const RejectedHandle=()=>{
	// 	const RejectedStatus='Rejected';
	// 	userApi.postInfluncerStatus(usersData._id,usersData.userId,RejectedStatus).then((data) => {
	// 		if(data){
	// 			commonService.isLoading.onNext(true);
	// 			commonService. forWarning('Rejected!', 'Warning');
	// 			setTimeout(() => commonService.isLoading.onNext(false), 500);
	// 			}
	// 	});
	// }
	return (
		<>
			<div className='page-layout simple tabbed' id='profile'>
				<div className='profile-header p-24'>
				<div className='button btn-postion'>
						<a className='button-sign back-btn' href="/admin/Influncer">Back</a>
					</div>
					<div className='name'style={{color:'white'}}> Influencer Information </div>
				{/* <div
						className='actions  '
						fxlayout='row'
						fxlayoutalign='end center'
					>
						<Button
							variant='contained'
							color='default'
							className='btn-theme-primary'
							onClick={ApprovedHandle}
						>
						Approved
						</Button>
						<Button
							variant='contained'
							color='default'
							className='btn-theme-primary'
							onClick={RejectedHandle}
						>
						Rejected
						</Button>
					</div>		 */}
				</div>
			</div>
			<div className='content'>
				<InfluncerTabs userData={usersData}></InfluncerTabs>
			</div>
		</>
	);
};
export default withRouter(InfluncerProfile);
