import React from 'react';

import OpportunityCard from '../../components/opportunities/OpportunityCard.js';

import './opportunities.scss';

const Opportunities = () => {
  const opportunities = [0,1];

  return (
      <>
        { opportunities.map(opportunity => <OpportunityCard key={opportunity}/>) }
      </>
  );
};

export default Opportunities;