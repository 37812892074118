import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


function createData(post, name, company) {
  return { post, name, company};
}

const rows = [
  createData('Head', 'Patrick', 'Elicit'),
  createData('Leader', 'li-shang','Zayshing Infotech'),
];

export default function CommonTable() {

  return (

          <Table  className="common-table" size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Post</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Company</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                  <TableRow key={row.post}>
                    <TableCell scope="row">
                      {row.post}
                    </TableCell>
                    <TableCell scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.company}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
  );
}