import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';

import PersonalInfo from './PersonalInfo';
import ProfileUpdate from './ProfileUpdate.js';
import UpdatePassword from './UpdatePassword.js';
import Portfolio from './Portfolio.js';

 const ProfileTabs = () => {
   // custom tab data
   const labelsData = [
     'Personal Info',
     'Update Profile',
     'Change Password'
   ];
   const tabsData = {
     0: <PersonalInfo></PersonalInfo>,
     1: <ProfileUpdate></ProfileUpdate>,
     2: <UpdatePassword></UpdatePassword>
   };

  return (
      <TabsUI labels={labelsData} tabs={tabsData} />
  )
};

export default ProfileTabs;