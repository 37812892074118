import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import page layout and container for private and public page;
import { PublicRouterLayout, PrivateRouterLayout } from './routerLayout';

export const PrivateRoute = (props) => {
	const isAuthenticated = localStorage['authToken']; // set temp auth

	const { component: Component, ...restProps } = props;

	if (!Component) return null;

	return (
		<Route
			{...restProps}
			render={(routeRenderProps) =>
				isAuthenticated ? (
					<PrivateRouterLayout>
						<Component {...routeRenderProps} />
					</PrivateRouterLayout>
				) : (
					<Redirect
						to={{
							pathname: '/admin/login',
							state: { from: routeRenderProps.location },
						}}
					/>
				)
			}
		/>
	);
};

export const PublicRoute = (props) => {
	const isAuthenticated = localStorage['authToken'];

	const { component: Component, ...restProps } = props;

	if (!Component) return null;

	return (
		<Route
			{...restProps}
			render={(routeRenderProps) =>
				!isAuthenticated ? (
					<PublicRouterLayout>
						<Component {...routeRenderProps} />
					</PublicRouterLayout>
				) : (
					<Redirect
						to={{
							pathname: '/admin/dashboard',
							state: { from: routeRenderProps.location },
						}}
					/>
				)
			}
		/>
	);
};
