import React, { useState, useEffect, useContext } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  makeStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as userApi from './../../api';
import AppContext from './../../context';
import Filter from './communityFilter.js';
import * as moment from 'moment';
const useStyles = makeStyles({
  EventTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'admins', label: 'Admin' },
  { id: 'name', label: 'Name' },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'location',
    label: 'Location',
  },

  {
    id: 'createdAt',
    label: 'Created On',
    sortable: true,
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'right',
  },
];

function createData(
  sno,
  admins,
  name,
  description,
  location,
  // memberRequest,
  createdAt,
  action
) {
  return {
    sno,
    admins,
    name,
    description,
    location,
    // memberRequest,
    createdAt,
    action,
  };
}
const rows = [];

const CommunityTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [communityData, setCommunityData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userData, setUsersData] = useState([]);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [searchClick, setSearchClick] = useState(false);

  useEffect(() => {
    let x = [];
    userApi.getAllCommunity().then((data) => {
      if (userResponse && userResponse.length) {
        for (let i = 0; i < userResponse.length; i++) {
          data.data.map((community, index) => {
            if (userResponse[i]._id === community.admins[0]) {
              x.push(
                createData(
                  index + 1,
                  userResponse[i].uniqueName ? userResponse[i].uniqueName : '',
                  community.name,
                  community.description,
                  community.location,
                  // community.memberRequest,
                  moment(community.createdAt).format('DD/MM/YYYY'),
                  <>
                    <VisibilityIcon
                      style={{ cursor: 'pointer', align: 'center' }}
                      onClick={() => history.push(`/admin/community/${community._id}`)}
                    />
                  </>
                )
              );
            }
          });
        }
      }
      setRowData(x);
      setCommunityData(x);
    });
  }, [userResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = async (filterForm) => {
    const data = communityData.filter((row) => {
      if (filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
      // if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;
      if(filterForm['startDate'] && filterForm['endDate']){
        var d1 = filterForm['startDate'].split("/");
        var d2 = filterForm['endDate'].split("/");
        var c = row['createdAt'].split("/");
        var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
        var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
        var check = new Date(c[2], parseInt(c[1])-1, c[0]);
        if(check >= from && check <= to){ return true }else{  return false };
        }
      return true;
    });
    setRowData(data);
  };
  const resetCommunityForm = () => {
    setRowData(communityData);
  };

  const handleSearch = () => {
    setSearchClick(true);
  };

  return (
    <div className="p-3 ">
      <div>
        <h2>
          Communities{' '}
          <div className="col-3 float-right">
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                  Show Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter handleFilter={handleFilter} resetCommunityForm={resetCommunityForm}   communityCreatedOn={[...new Set(communityData.map((data) => data.createdAt))]}></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.EventTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">
                              {column.format && typeof value === 'boolean' ? column.format(value) : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25,50,100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CommunityTable);
