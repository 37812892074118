import React, { useState, useEffect, useRef } from 'react';

import { Card, CardContent, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import * as moment from 'moment';

import './styles.css';
const Filter = ({ resetReferralFilterForm, handleFilter, usedCodeGroups }) => {
  const [formData, setFormData] = useState({
    email: '',
    status: '',
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const [filterForm, setFilterForm] = useState({
    text: '',
    vipCode: '',
    createdAt: '',
    startDate: '',
    endDate: '',
  });
  const { email } = formData;
  const inputTo = useRef(null);
  const inputFrom = useRef(null);
  // reset filter form and data
  const handleReset = () => {
    const data = {
      text: '',
      vipCode: [''],
      createdAt: '',
    };
    inputTo.current.value = '';
    inputFrom.current.value = '';
    setFilterForm(data);
    resetReferralFilterForm(data);
  };
  // filter lead table according to filed value changes
  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    handleFilter(data);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <Card>
          <CardContent>
            <form>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <TextField
                    value={filterForm.text}
                    className="w-100 mt-3"
                    onChange={(e) => handleChanges('text', e.target.value)}
                    placeholder="Search"
                  />
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterForm.vipCode}
                      onChange={(e) => handleChanges('vipCode', e.target.value)}
                    >
                      {usedCodeGroups.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <FormControl className="MuiInput-underline">
                    {' '}
                    <input
                      type="date"
                      className="mt-3 MuiInputBase-input MuiInput-input"
                      placeholder="Date from"
                      ref={inputTo}
                      selected={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => handleChanges('startDate', moment(e.target.value).format('DD/MM/YYYY'))}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <FormControl className="MuiInput-underline">
                    <input
                      type="date"
                      placeholder="Date to"
                      className="mt-3 MuiInputBase-input MuiInput-input date"
                      ref={inputFrom}
                      selected={filterForm.endDate}
                      //minDate={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => handleChanges('endDate', moment(e.target.value).format('DD/MM/YYYY'))}
                    />
                  </FormControl>
                  {/* <FormControl className="MuiInput-underline">
										{' '}
                    <input type="date" onChange={(e) => handleChanges('createdAt', moment(e.target.value).format('DD/MM/YYYY'))} className="MuiInputBase-input MuiInput-input mt-3" />
									</FormControl> */}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <Button
                    variant="contained"
                    color="default"
                    className="btn-theme-primary mt-3 float-none"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Filter;
