import React from 'react';
import {
    makeStyles,
    Drawer,
    Button,
    Divider,
    TextField,
    FormGroup
} from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: 500,
    '@media (max-width: 780px)' : {
      width: '100%'
    }
  },
  fullList: {
    width: 'auto',
  },
});

const LeadForm = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({right: false});

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const sideList = side => (
      <div
          className={classes.list}
          role="presentation"
          >
        <form className="login100-form  p-2" id="login_form" onSubmit={(e) => handleSubmit(e)}>
          <div className="inner_form">
            <div className="fields">
              <h6 className="m-2">Personal Information</h6>
              <div className="row p-2 ">
                <FormGroup className="col-6"><TextField  label="First Name" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Last Name" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Job" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Department" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Account Name" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Email"/></FormGroup>
                <FormGroup className="col-6"><TextField label="Mobile No." /></FormGroup>
              </div>
              <h6 className="m-2">Address</h6>
              <div className="row p-2 mb-3">
                <FormGroup className="col-6"><TextField label="Address" /></FormGroup>
                <FormGroup className="col-6"><TextField label="City" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Postal code" /></FormGroup>
                <FormGroup className="col-6"><TextField label="Region/State" /></FormGroup>
                <FormGroup className="col-6"><TextField label="country" /></FormGroup>
              </div>
              <Divider />
              <div className="ml-2 mt-5"><button className="theme_btn btn_lg btn_load" id="submit" type="submit">Submit</button></div>
            </div>
          </div>
        </form>
      </div>
  );

  return (
      <div>
      </div>
  )
};

export default LeadForm;