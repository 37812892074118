import React from 'react';
import { Validators }  from "react-reactive-form";

import { NAME_REGEXP } from "../../config.js";
import { TextInput, CheckBox } from "../shared/ui-components/common-form/Field.js";

// Field config to configure form
export const FieldConfig = {
  controls: {
    first_name: {
      options: {
        validators: [Validators.required, Validators.minLength(2), Validators.pattern(NAME_REGEXP)]
      },
      render: TextInput,
      meta: { label: 'First Name', type: 'text' }
    },
    last_name: {
      options: {
        validators: [Validators.required, Validators.minLength(2), Validators.pattern(NAME_REGEXP)]
      },
      render: TextInput,
      meta: { label: 'Last Name', type: 'text' }
    },
    email: {
      options: {
        validators: [Validators.required, Validators.email]
      },
      render: TextInput,
      meta: { label: 'Email', type: 'email' }
    },
    password: {
      options: {
        validators: [Validators.required, Validators.minLength(6)]
      },
      render: TextInput,
      meta: { label: 'Password', type: 'password' }
    },
    confirm_password: {
      options: {
        validators: [Validators.required, Validators.minLength(6)]
      },
      render: TextInput,
      meta: { label: 'Confirm Password', type: 'password' }
    },
    terms_and_condition: {
      render: CheckBox,
      meta: { label: 'By checking this box, I agree to terms and conditions.' }
    },
    $field_0: {
      isStatic: false,
      render: ({ invalid, meta: { handleReset } }) => (
          <div>
            <button
                color="default" className="mt-3 mb-3 w-50 cs_btn_rdus cs_primary"
                type="submit"
                disabled={invalid}
                >
              Sign UP
            </button>
          </div>
      )
    }
  }
};