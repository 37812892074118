import React from 'react';
import { NavLink }  from "react-router-dom";


const SideMenu = ({icon, title, link, exact}) => {
  return (
      <li>
        <NavLink className="app-menu__item" to={link} activeClassName="active" exact={exact}> <span className="icons_blue">
                  <img src={require(`../../../../assets/images/icon/${icon}_blue.png`)} alt="icon" />
                </span>
                  <span className="icons_white">
                    <img src={require(`../../../../assets/images/icon/${icon}_white.png`)} alt="icon" />
                  </span>
          <span className="app-menu__label">{title}</span>
        </NavLink>
      </li>
  )
};

export default SideMenu;