import React, { useState, useEffect } from 'react';

import { withRouter, Redirect, useHistory } from 'react-router-dom';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';

import ArticleTabs from '../../components/article-profile/articleTabs';
import './profile.scss';

const ArticleProfile = (props) => {
	const [usersData, setUsersData] = useState([]);

	const toggelActive = (confirm) => {
		if (confirm) {
			userApi.toggelActive(props.match.params.id).then((data) => {
				setUsersData(data.data);
				console.log(data);
			});
		}
		commonService.isDialogOpen.onNext(false);
	};
	
	useEffect(() => {
		// Update the document title using the browser API
		userApi.getArticleDetail(props.match.params.id).then((data) => {
			setUsersData(data.data);
		});
	}, []);
	return (
		<>
			<div className='page-layout simple tabbed' id='profile'>
			<div className='profile-header p-24'style={{position:'relative'}}>
					<div className='button btn-postion'>
						<a className='button-sign back-btn' href="/admin/articles">Back</a>
					</div>
						<div className='name'> {usersData.title} </div>
				</div>
			</div>
			<div className='content'>
				<ArticleTabs userData={usersData}></ArticleTabs>
			</div>
		</>
	);
};
export default withRouter(ArticleProfile);
