import React, { useState,useMemo } from 'react';
import AppContext from '.';
const ContextProvider = ({ children }) => {
  const [userResponse, setUserResponse] = useState([]);
  const value = useMemo(() => ({ userResponse, setUserResponse}), [userResponse, setUserResponse]);

  return (
    <AppContext.Provider value={ value }> 
      {children} 
    </AppContext.Provider>
  );
}
export default ContextProvider;