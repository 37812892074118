import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const Connection = (props) => {
    return (
        <>
		<div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
       	<Card className='profile-box info-box general '>
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>
						Connections 
						</span>
					}
				></CardHeader>
				<div className="height-scroll">			{props.userData.connections.length?props.userData.connections.map((data)=>
				<CardContent className=" row info-line">
					<div className="col-md-3">
						
				{data.profilePics && data.profilePics.length?data.profilePics.map((x)=><img
								className='profile-image avatar huge'
								src={x.url}
								alt='profile'
							/>
							):""}
					</div>
					<div className="col-md-9">
					<div className='py-1'>
						 <div className='title float-left'>Name :-</div>
						<div className='info float-left pl-2'>{data['uniqueName']}</div>	
						<div className='clearfix'></div>	
						</div>
						<div className='py-1'>
						<div className='title float-left'>Level :-</div>
                        <div className='info float-left pl-2'>{data['level']}</div>	
						<div className='clearfix'></div>
						</div>
						<div className='py-1'>
						<div className='title float-left'>Connection Type :-</div>
                        <div className='info float-left pl-2'>{data.connectionType}</div> 
						<div className='clearfix'></div>
						</div>
						
						</div>
				</CardContent>):<CardContent>No information available yet</CardContent>}
				</div>

			</Card>
			</div>
			</div>
	</>
    )
};

export default Connection;