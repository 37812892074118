import React from 'react';
import { Validators } from "react-reactive-form";
import {
  Card,
  CardContent
} from '@material-ui/core';

import * as commonService from '../../utils/CommonService.js';

import CommonForm from '../shared/ui-components/common-form';
import { TextInput } from '../shared/ui-components/common-form/Field.js';

// Field config to configure form
const fieldConfig = {
  controls: {
    first_name: {
      options: {
        validators: Validators.required
      },
      render: TextInput,
      meta: { label: 'First Name', type: 'text' }
    },
    last_name: {
      options: {
        validators: Validators.required
      },
      render: TextInput,
      meta: { label: 'Last Name', type: 'text' }
    },
    email: {
      options: {
        validators: [Validators.required, Validators.email]
      },
      render: TextInput,
      meta: { label: 'Email', type: 'email' }
    },
    $field_0: {
      isStatic: false,
      render: ({ invalid, meta: { handleReset } }) => (
        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
          <button
            color="default" className="mt-3 mb-3 w-50 cs_btn_rdus cs_primary"
            type="submit"
            disabled={invalid}
          >
            Update Profile
            </button>
        </div>
      )
    }
  }
};

const ProfileUpdate = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
    commonService.forSuccess('Profile updated successfully!', 'Success');
  };

  const setForm = (form) => { };

  return (
    <div className="row">
      {/* <div className="col-12"> */}
      <div className="col-md-6">
        <Card>
          <CardContent>
            <CommonForm setForm={setForm} fieldConfig={fieldConfig} handleSubmit={handleSubmit}></CommonForm>
          </CardContent>
        </Card>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ProfileUpdate;