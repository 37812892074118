import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
	makeStyles,
	withStyles,
	GridList,
	GridListTile,
	Drawer,
	Button,
	Divider,
	TextField,
	Card,
	CardContent,
	CardHeader,
	FormGroup,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: '100%',
		height: 345,
	},
}));

const ProgressInfo = (props) => {
	return (
		<>
		<div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>
							Progress Information
						</span>
					}
				></CardHeader>
				<CardContent className="height-scroll">
					<div>
					<div className='info-line'>
					<div className='py-1'>
						<div className='title float-left'>Relationships:</div>
						<div className='info float-left pl-2'>{props.userData['progress']?.relationShips}</div>
						<div className='clearfix'></div>
                        </div>
						<div className='py-1'>
						<div className='title float-left'>Gender:</div>
						<div className='info float-left pl-2'>{props.userData['progress']?.gender}</div>
						<div className='clearfix'></div>
						</div>
                        <div className='py-1'>
						<div className='title float-left'>Sexuality:</div>
						<div className='info float-left pl-2'>{props.userData['progress']?.sexuality}</div>
						<div className='clearfix'></div>
						</div>
                        <div className='py-1'>
						<div className='title float-left'>Personal Health:</div>
						<div className='info float-left pl-2'>{props.userData['progress']?.personalHealth}</div>
						<div className='clearfix'></div>
						</div>
                        <div className='py-1'>
						<div className='title float-left'>Community:</div>
						<div className='info float-left pl-2'>{props.userData['progress']?.community}</div>
						<div className='clearfix'></div>
                        </div>
						<div className='py-1'>
						<div className='title float-left'>Others:</div>
						<div className='info float-left pl-2'>{props.userData['progress']?.others}</div>
						<div className='clearfix'></div>
						</div>
						
					</div>

					</div>
				
							</CardContent>
			</Card>
			</div>
			</div>
		</>
	);
};

export default withRouter(ProgressInfo);
