import React, { useState, useEffect, useContext } from 'react';
import AppContext from './../../context';
import { withRouter } from 'react-router';
import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';
import { IndeterminateCheckBoxOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const CommunityMemberRequest = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [communityBy, setCommunityBy] = useState();
  const tileData =
    props.userData.eventImage?.length > 0
      ? props.userData.eventImage.map((profilePic) => {
          let rObj = {};
          rObj['img'] = profilePic;
          return rObj;
        })
      : [];
  
  return (
    <>
    <div className="row">
		<div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Community Member Request</span>}
        ></CardHeader>
        <div className="height-scroll">
          {props.userData.membersRequest && props.userData.membersRequest.length?props.userData.membersRequest.map((data)=>
			 <CardContent  className="row info-line">
				 <div className="col-md-3"><img
						className='profile-image avatar huge'
						 src={data.profilePics[0].url}
						 alt='profile'
						 />
				 </div>
				 <div className="col-md-9">
              <div className='py-1'>
					 <div className='title float-left'>Name</div>
					 <div className='info float-left pl-2'>{data.uniqueName}</div>
           </div>
           <div className='clearfix'></div>
           <div className='py-1'>
					 <div className='title float-left'>Level</div>
					 <div className='info float-left pl-2' >{data.level}</div>
           </div>
           <div className='clearfix'></div>
           <div className='py-1'>
					 <div className='title float-left'>Connection Type</div>
					 <div className='info float-left pl-2' >{data.connectionType}</div>	
           </div>
           <div className='clearfix'></div>
					 </div>
			 </CardContent>):<CardContent>No information available yet</CardContent>}
      </div>
      </Card>
      </div>
      </div>
    </>
  );
};

export default withRouter(CommunityMemberRequest);
