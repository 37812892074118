import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AppContext from './../../context';
import * as userApi from './../../api';
import Filter from './guidelineFilter';
import * as moment from 'moment';
import * as commonService from '../../utils/CommonService';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import JoditEditor from 'jodit-react';
import { Image } from '@material-ui/icons';
import { Form } from 'formik';
import { id } from 'date-fns/locale';
import { options } from 'toastr';

const useStyles = makeStyles({
  PostTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'title', label: 'Title' },
  { id: 'content', label: 'Content' },
  {
    id: 'description',
    label: 'Description',
    align: 'right',
  },
  {
    id: 'references',
    label: 'References',
    sortable: true,
    align: 'right',
  },
  {
    id: 'createdAt',
    label: 'Created on',
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'right',
  },
];

function createData(sno,title, content, description, references, createdAt, action) {
  return {
    sno,
    title,
    content,
    description,
    references,
    createdAt,
    action,
  };
}
const rows = [];

const GuidelineTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [guidelineData, setGuidelineData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userData, setUsersData] = useState([]);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [searchClick, setSearchClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [myimage,setMyImage] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [discription, setDiscription] = useState('');
  const [question, setQuestion] = useState('');
  const [reference, setReference] = useState('');
  const [guidelinesData, setGuidelinesData] = useState([]);
  const [guidelineUpdate, setGuidelineUpdate] = useState(false);
  const [Article_id, setArticle_id] = useState('');
  const [updateOptionId,setUpdateOptionId]= useState('');
  const [updateImage,setUpdateImage]=useState('');

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const [optionData, setOptionData] = useState([
    {
      id: 1,
      name: 'name',
      label: 'Option 1',
    },
    {
      id: 2,
      name: 'name',
      label: 'Option 2',
    },
    {
      id: 3,
      name: 'name',
      label: 'Option 3',
    },
    {
      id: 4,
      name: 'name',
      label: 'Option 4',
    },
  ]);

  const addTags = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setTags([...tags, event.target.value.toString()]);
      event.target.value = '';
    }
  };
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const addImages = (event) => {
    // const imageData = URL.createObjectURL(event.target.files[0]);
    setMyImage(event.target.files[0]);
  };

  const clearNewArticleForm = () => {
    setMyImage(null);
    setTitle('');
    setDiscription('');
    setReference('');
    setTags([]);
    setContent('');
    setQuestion('');
  };

  const handleClickOpen = () => {
    setGuidelineUpdate(false);
    clearNewArticleForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (articleId) => {
    commonService.isDialogOpen.onNext({
      open: true,
      data: {
        message: 'Wanted to delete this article ?',
      },
      cancelText: 'Cancle',
      confirmText: 'ok',
      onConfirm: (value) => handleDeleteApi(articleId, value),
    });
  };

  const handleDeleteApi = (articleId, confirm) => {
    if (confirm === true) {
      const index = rowData.indexOf(articleId);
      if (index > -1) {
        rowData.splice(index, 1);
      }
      commonService.isLoading.onNext(true);
      userApi.deleteArticleById(articleId).then((data) => {
        if (data) {
          commonService.forSuccess('Deleted sucessfully', 'Success');
          window.location.reload();
          setTimeout(() => commonService.isLoading.onNext(false), 500);
        }
      });
    }
    commonService.isDialogOpen.onNext(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // custom filter by text , referralcode and used & unused
  const handleFilter = (filterForm) => {
    const data = guidelineData.filter((row) => {
      // if (filterForm['Status'] && row['Status'] !== filterForm['Status']) return false;
      if(filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
      // if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;
      if (filterForm['startDate'] && filterForm['endDate']) {
        var d1 = filterForm['startDate'].split('/');
        var d2 = filterForm['endDate'].split('/');
        var c = row['createdAt'].split('/');
        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
        if (check >= from && check <= to) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    });
    setRowData(data);
  };

  const resetGuidelineFilterForm = () => {
    setRowData(guidelineData);
  };
  const handleSearch = () => {
    setSearchClick(true);
  };

  const titleFormData = (event) => {
    setTitle(event.target.value);
  };

  const discriptionFormData = (event) => {
    setDiscription(event.target.value);
  };
  const referenceFormData = (event) => {
    setReference(event.target.value);
  };

  const questionFormData = (event) => {
    setQuestion(event.target.value);
  };

  const handleTextbox = (event) => {
   // setGuidelineUpdate(false);
    let option = [...optionData];
    for (let i = 0; i < option.length; i++) {
      if (option[i].id+'option' == event.target.id || option[i]._id+'option' == event.target.id) {

        if(guidelineUpdate){
          option[i].option = event.target.value;
        }
        else{
          option[i].value = event.target.value;
        }

        
      }
    }
    setOptionData(option);
  };

  const handleRadio = (event) => {
   //setGuidelineUpdate(false);
   setUpdateOptionId('');
    let DataChecked = [];
    let option = [...optionData];
    for (let i = 0; i < option.length; i++) {
      if (option[i].id == event.target.id || option[i]._id == event.target.id) {
        option[i].checked = true;
        option[i].isCorrectAnswer = true;
      } else {
        option[i].checked = false;
        option[i].isCorrectAnswer = false;
      }
    }
    setOptionData(option);
  };
  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const handleOptionSubmitForm = () => {
    let option = [...optionData];
    let newArray = [];
    for (let i = 0; i < option.length; i++) {
      if(guidelineUpdate===true){
        newArray.push({ option: option[i].option, isCorrectAnswer: option[i].isCorrectAnswer });
      }
      else{
        newArray.push({ option: option[i].value, isCorrectAnswer: option[i].checked });
      }
    }
    return newArray;
  };
  const handleSubmit = async (e) => {
     e.preventDefault();  
    commonService.isLoading.onNext(true);
    const fd = new FormData();
    fd.append('file', myimage);
    fd.append('title', title);
    fd.append('description', discription);
    fd.append('reference', reference);
    fd.append('tags', tags);
    fd.append('content', content);
    fd.append('question', question);
   
    if (guidelineUpdate === false) {
       fd.append('options', JSON.stringify(handleOptionSubmitForm()));
      let resp = await userApi.postAdminArticle(fd);
      commonService.forSuccess('Community Guideline created successfully');
      setGuidelinesData(resp.data);
      setRowData((state,index) => {
        if(resp.data.articleType === 'guideline'){
        return [
          ...state,
          createData(
            index + 1,
            resp.data.title,
            resp.data.content,
            resp.data.description,
            resp.data.references,
            moment(resp.data.createdAt).format('DD/MM/YYYY'),
            <>
              <DeleteIcon style={{ cursor: 'pointer', align: 'center' }} onClick={() => handleDelete(resp.data._id)} />,
              <EditIcon style={{ cursor: 'pointer', align: 'center' }} onClick={() => handleEdit(resp.data._id)} />
            </>
          ),
        ];
      }
      });
      commonService.isLoading.onNext(false);
      clearNewArticleForm();
      window.location.reload();
      setOpen(false);
    }else if (guidelineUpdate === true) {
      fd.append('options', JSON.stringify(optionData));
      let resp = await userApi.adminGuideUpdate(Article_id,fd);
      commonService.forSuccess('Community Guideline updeted successfully', 'Success');
      setGuidelinesData(resp.data);
      setRowData((state,index) => {
        if(resp.data && resp.data !== undefined){
        return [
          ...state,
          createData(
            index + 1,
            resp.data.title,
            resp.data.content,
            resp.data.description,
            resp.data.references,
            moment(resp.data.createdAt).format('DD/MM/YYYY'),
            <>
              <DeleteIcon style={{ cursor: 'pointer', align: 'center' }} onClick={() => handleDelete(resp.data._id)} />,
              <EditIcon style={{ cursor: 'pointer', align: 'center' }} onClick={() => handleEdit(resp.data._id)} />
            </>
          ),
        ];
      }
      });
      window.location.reload();
      commonService.isLoading.onNext(false);
      //clearNewArticleForm();
      setOpen(false);
    }
  };

  useEffect(() => {
    let x = [];
    userApi.getAdminGuideline().then((data) => {
      setGuidelinesData(data.data);
      data.data.map((data, index) => {
        if(data.articleType === 'guideline'){
        x.push(
          createData(
            index + 1,
            data.title,
            data.content,
            data.description,
            data.references,
            moment(data.createdAt).format('DD/MM/YYYY'),
            <>
              <DeleteIcon
                style={{ cursor: 'pointer', align: 'center' }}
                onClick={() => handleDelete(data._id, index)}
              />
              ,
              <EditIcon style={{ cursor: 'pointer', align: 'center' }} onClick={() => handleEdit(data._id)} />
            </>
          )
        );
          }
      });
      setRowData(x);
      setGuidelineData(x);
    });
  }, [userResponse]);

  const handleEdit = (article_id) => {
    const filterData = guidelinesData.find((x) => x._id === article_id);
    setArticle_id(article_id);
    if (filterData) {
      setGuidelineUpdate(true);
      setTitle(filterData.title);
      setMyImage(filterData.mediaLinks[0]);
      setDiscription(filterData.description);
      setContent(filterData.content);
      setQuestion(filterData.question);
      setTags([filterData.tags]);
      setReference(filterData.references);
      const OptionTrue = filterData.options.find((x)=>x.isCorrectAnswer===true);
      setOptionData(filterData.options);
      if(OptionTrue){
      setUpdateOptionId(OptionTrue._id);
      }
    }
    setOpen(true);
  };
  return (
    <div className="p-3 ">
      <div>
        <h2>
          Community Guidelines{' '}
          <div className="col-3 float-right">
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}

            <Button
              variant="contained"
              color="default"
              className="btn-theme-primary"
              onClick={(e) => {
                handleClickOpen(e);
              }}
            >
              Create
            </Button>
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetGuidelineFilterForm={resetGuidelineFilterForm}
          usedCodeGroups={[...new Set(guidelineData.map((data) => data.vipCode))]}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.PostTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">
                              {column.format && typeof value === 'boolean' ? column.format(value) : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25, 50, 100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <div className="row mb-5">
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle style={{ textAlign: 'center' }}>Community Guideline
              <span style={{float:'right', cursor:'pointer'}} onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg></span>
              </DialogTitle>
              
              <DialogContent>
                <DialogContentText>
                  <form encType="multipart/form-data" onSubmit={(e) => handleSubmit(e)} onKeyDown={onKeyDown}>
                    <input
                      value={title}
                      onChange={(e) => titleFormData(e)}
                      placeholder="Title"
                      type="title"
                      name="title"
                      required
                    />
                    <div className="tags-input multi_input">
                      <ul>
                        {tags.map((tag, index) => (
                          <li
                            key={index}
                          >
                            <span>{tag}</span>
                            <i onClick={() => removeTags(index)}>
                              <CloseIcon />
                            </i>
                          </li>
                        ))}
                      </ul>
                      <input type="text" onKeyUp={(event) => addTags(event)} placeholder="Press enter to add tags" />
                    </div>
                    <input
                      value={discription}
                      onChange={(e) => discriptionFormData(e)}
                      placeholder="Description"
                      type="description"
                      name="description"
                      required
                    />
                    <div className="image-input">
                      <input type="file"  onChange={(e)=>addImages(e)} placeholder="Add images" />
                    </div>
                    <div className="max-width_9">
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                        required 
                      />
                    </div>
                    <div>
                      <input
                        value={reference}
                        onChange={(e) => referenceFormData(e)}
                        placeholder="Reference"
                        type="Reference"
                        name="Reference"
                        required
                      />
                    </div>
                    <div className="queAnsInputs" style={{ display: 'inline-block', float: 'left' }}>
                      <div className="queAnsFields" style={{ width: '100%' }}>
                        <input
                          value={question}
                          onChange={(e) => questionFormData(e)}
                          placeholder="Question"
                          type="Question"
                          name="Question"
                          required
                        />
                        {optionData.map((item,i) => {
                          return (
                            <div className="row" key={i}>
                              <div className="" style={{ width: '10%', float: 'left', padding: '5px' }}>
                                <input
                                  type="radio"
                                  id={item._id != undefined ? item._id : i+1}
                                  name="name"
                                  // checked={updateOptionId===item._id}
                                  label={item.label}
                                  defaultChecked={updateOptionId === item._id}
                                  onClick={(event) => handleRadio(event)}
                                />
                              </div>
                              <div className="" style={{ width: '85%', float: 'left', padding: '5px', margin: '0' }}>
                                <input
                                  type="text"
                                  id={item._id != undefined ? item._id+'option' : i+1+'option'}
                                  name='option'
                                  label={item.label}
                                  value={item.option}
                                  placeholder="Enter Answer"
                                  style={{ width: '100%', margin: '0px' }}
                                  onChange={(event) => handleTextbox(event)}
                                  required
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <button type="submit">Submit</button>
                    <button onClick={handleClose}>cancel</button>
                  </form>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(GuidelineTable);
