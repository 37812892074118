import React, { useState, useEffect, useContext } from 'react';

import { withRouter, Redirect, useHistory } from 'react-router-dom';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';
import AppContext from './../../context';
import ProfileTabs from '../../components/post-profile/ProfileTabs';
import './profile.scss';

const UserProfile = (props) => {
  let history = useHistory();
  const [usersData, setUsersData] = useState([]);
  const { userResponse, setUserResponse } = useContext(AppContext);

 
  useEffect(() => {
    // Update the document title using the browser API
    userApi.getPostDetail(props.match.params.id).then((data) => {
      setUsersData(data.data);
    });	
  }, [setUsersData]);
  return (
    <>
      <div className="page-layout simple tabbed" id="profile">
        <div className="profile-header p-24" style={{ position: 'relative' }}>
          <div className="button btn-postion">
            <a
              className="button-sign back-btn"
              
              href="/admin/posts"
            >
              Back
            </a>
          </div>
          <div className="user-info">
            {userResponse.map((data) => (
              <div className="name"> {data._id === usersData.userId ? data.uniqueName : ''} </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content">
        <ProfileTabs userData={usersData}></ProfileTabs>
      </div>
    </>
  );
};
export default withRouter(UserProfile);
