import React from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

const ClientCard = ({ props, viewClasses, user }) => {
  const history = useHistory();

  return (
    <>
      <div className={viewClasses.viewContainerClass}>
        <div
          className={`${viewClasses.viewCardClass} invest-cart-content`}
          onClick={() => history.push(`/admin/user/${user._id}`)}
        >
          <div className="isoCardImage">
            <img alt="#" src={require(`../../../assets/images/avatar/${user.avatar}.png`)} />
          </div>
          <div className="isoCardContent">
            <h6 className="isoCardTitle">
              <span>{user.fullName}</span>
            </h6>
            <span className="isoCardDate">{user.gender}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ClientCard);
