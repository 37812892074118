import React from 'react'

const FormWrapper = ({children}) => {
  return (
      <div>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <div className="login100-more">
                <div className="img-login" style={{height:'100vh'}}>
                  <img src={require("../../../../assets/images/thumbnails/dating.jpg")} alt="sign in" />
                </div>
              </div>
              <div className="login100-form" id="login_form" >
                <div className="inner_form">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default FormWrapper;