import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';
import PartnerInfo from './PartnerInfo';

const partnerTabs = (props) => {
  // custom tab data
  const labelsData = ['partner Info'];
  const tabsData = {
    0: <PartnerInfo userData={props.userData}></PartnerInfo>,
  };

  return <TabsUI labels={labelsData} tabs={tabsData} />;
};

export default partnerTabs;
