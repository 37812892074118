import React, {useState} from 'react';
import { Link }  from "react-router-dom";

import * as commonService from "../../utils/CommonService.js";
import { checkIfMatchingPasswords } from "../../utils/Validation.js";

import { FieldConfig } from './FieldConfig.js'
import FormWrapper from "../../components/shared/ui-components/form-wrapper";
import CommonForm from "../../components/shared/ui-components/common-form";

const RegistrationForm = ({ history }) => {
  const [registerForm, setRegisterForm] = useState({});

  const handleSubmit=(e) => {
    e.preventDefault();
    commonService.forSuccess('Registered successfully!' , 'Success');
    localStorage['authToken'] = JSON.stringify(registerForm.value);
    history.push('/');
  };

  const setForm = (form) => {
    form.validator = checkIfMatchingPasswords("password", "confirm_password");
    setRegisterForm(form);
  };

  return (
      <FormWrapper>
        <div className="fields lo_All">
          <h1>Sign Up</h1>
          <h2>Already have a account?<Link className="underline" to="/login">Sign In</Link></h2>
          <CommonForm setForm={setForm} fieldConfig={FieldConfig} handleSubmit={handleSubmit}></CommonForm>
        </div>
      </FormWrapper>
  );
};

export default RegistrationForm;