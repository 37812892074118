import React, { useState, useEffect } from 'react';

import { withRouter, Redirect, useHistory } from 'react-router-dom';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';

import ProfileTabs from '../../components/community-profile/CommunityTabs';
import './profile.scss';

const CommunityProfile = (props) => {
	const [usersData, setUsersData] = useState([]);

	useEffect(() => {
		// Update the document title using the browser API
		userApi.getCommunityById(props.match.params.id).then((data) => {
			setUsersData(data.data);
		});
	}, [setUsersData]);
	return (
		<>
			<div className='page-layout simple tabbed' id='profile'>
			<div className='profile-header p-24'style={{position:'relative'}}>
					<div className='button btn-postion'>
						<a className='button-sign back-btn' href="/admin/community">Back</a>
					</div>
					<div className='user-info'>
						{
						usersData.communityImages ? (
							<img
								className='profile-image avatar huge'
								src={usersData.communityImages}
								alt='profile'
							/>
						) : (
							<img
								className='profile-image avatar huge'
								src={require('../../assets/images/avatar/undefined.png')}
								alt='profile'
							/>
						)}
						<div className='name'> {usersData.name} </div>
					</div>
				</div>
			</div>
			<div className='content'>
				<ProfileTabs userData={usersData}></ProfileTabs>
			</div>
		</>
	);
};
export default withRouter(CommunityProfile);
