import React,{useState} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const PersonalInfo = () => {
  const [adminData,setAdminData]=useState(JSON.parse(window.localStorage.getItem('adminDetail')));
  
  return (
      <>
      <div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader className="card-header"  title={
        <span className="title card-title">General Information</span>
        }>
        </CardHeader>
        <CardContent className="height-scroll">
          <div className="info-line">
            <div className="title">UserName</div>
            <div className="info">{adminData.email}</div>
          </div>
          {/* <div className="info-line">
            <div className="title">Birthday</div>
            <div className="info">February 30th, 1974</div>
          </div>
          <div className="info-line">
            <div className="title">Locations</div>
            <div className="info location  " fxlayout="row" fxlayoutalign="start center" >
              <span >London, UK</span>
              <LocationOnIcon className="s-16 ml-4 mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true"></LocationOnIcon>
            </div>
            <div className="info location  " fxlayout="row" fxlayoutalign="start center">
              <span >New York, USA</span>
              <LocationOnIcon className="s-16 ml-4 mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true"></LocationOnIcon>
            </div>
          </div>
          <div className="info-line">
            <div className="title">About Me</div>
            <div className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget pharetra felis, sed ullamcorper dui. Sed et elementum neque. Vestibulum pellente viverra ultrices. Etiam justo augue, vehicula ac gravida a, interdum sit amet nisl. Integer vitae nisi id nibh dictum mollis in vitae tortor.</div> */}
          {/* </div> */}
        </CardContent>
      </Card>
         </div>
         </div>
          </>
  )
};

export default PersonalInfo;