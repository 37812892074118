import React, { useState, useEffect,useContext } from 'react';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
  makeStyles,
  withStyles,
  GridList,
  GridListTile,
  Drawer,
  Button,
  Divider,
  TextField,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';

// user api
import * as userApi from '../../api';
import AppContext from './../../context';
// service
import * as commonService from '../../utils/CommonService.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const EventInfo = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);

  const tileData =
    props.userData.eventImage?.length > 0
      ? props.userData.eventImage.map((profilePic) => {
          let rObj = {};
          rObj['img'] = profilePic;
          return rObj;
        })
      : [];

  return (
    <>
    <div className="row">
    <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">General Information</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div>
            <div className="info-line">
              <div className="title">Title</div>
              <div className="info">{props.userData?.eventTitle}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="title">Event Description</div>
            <div className="info">{props.userData['eventDesc']}</div>
          </div>
          <div className="info-line">
            <div className="title">Event Location</div>
            <div className="info">{props.userData['eventLocation']}</div>
          </div>
        </CardContent>
      </Card>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader className="card-header" title={<span className="title card-title">Event Images</span>}></CardHeader>
        <CardContent className="height-scroll">
          <div className={classes.root}>
            <GridList cellHeight={160} className={classes.gridList} cols={5}>
              {tileData && tileData.length
                ? tileData.map((tile) => (
                    <GridListTile key={tile.img} cols={tile.cols || 1}>
                      <img src={tile.img} alt={tile.title} />
                    </GridListTile>
                  ))
                : 'No information available yet'}
            </GridList>
          </div>
        </CardContent>
      </Card>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="mt-3 profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Event Group & Tags</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div className="info-line">
            <div className="title">Event Group</div>
            <div className="info  ">
              <span>
                {props.userData.eventGroup && props.userData.eventGroup.length
                  ? props.userData.eventGroup.map((data, index) => <div key={index}>{data}</div>)
                  : 'No information available yet'}
              </span>
            </div>
          </div>

          <div className="info-line">
            <div className="title">Tags</div>
            <div className="info">
              <span>
                {props.userData.eventTags && props.userData.eventTags.length
                  ? props.userData.eventTags.map((data, index) => <div style={{ display: 'inline' }}>{data},</div>)
                  : 'No information available yet'}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="mt-3 profile-box info-box general">
        <CardHeader className="card-header" title={<span className="title card-title">Commented By</span>}></CardHeader>
        <CardContent className="height-scroll">
          <div className="info-line">
            <div className="title">Comments</div>
            <div className="info  ">
              <span>
                {props.userData.comments && props.userData.comments.length
                  ?
                      props.userData.comments.map((data, index) =>
                      userResponse.map((userdata, id) => 
                      userdata._id===data.userId? <div key={index}><div>commented by:{userdata.uniqueName}</div>comment:{data.comment}</div> :''
                      )
                     )
                      
                    
                  : 'No information available yet'}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="mt-3 profile-box info-box general">
        <CardHeader className="card-header" title={<span className="title card-title">Event Group</span>}></CardHeader>
        <CardContent className="height-scroll">
          <div className="info-line">
            <div className="title">Event Group</div>
            <div className="info  ">
              <span>
                {props.userData.eventGroup && props.userData.eventGroup.length
                  ?   props.userData.eventGroup.map((data, index) =>                    
                     <div style={{display:'inline'}}>{data},</div>
                      )
                  : 'No information available yet'}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
      </div>
      </div>
    </>
  );
};

export default withRouter(EventInfo);
