import React, { useContext, useState, useEffect } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Button,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import AppContext from './../../context';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import * as commonService from '../../utils/CommonService';
import * as userApi from './../../api';
import Filter from './referralFilter';
import * as moment from 'moment';
const useStyles = makeStyles({
  PostTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'email', label: 'Email' },
  { id: 'referralCode', label: 'Referral Code' },
  {
    id: 'vipCode',
    label: 'Status',
    align: 'right',
  },
  {
    id: 'createdAt',
    label: 'Created on',
    sortable: true,
    align: 'right',
  },
];

function createData(sno,email, referralCode, vipCode, createdAt) {
  return {
    sno,
    email,
    referralCode,
    vipCode,
    createdAt,
  };
}
const rows = [];

const ReferralTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [referralData, setReferralData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userData, setUsersData] = useState([]);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [searchClick, setSearchClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    status: '',
  });
  const { email } = formData;
  useEffect(() => {
    let x = [];
    userApi.getAllAdminReferral().then((data) => {
      data.data.map((data, index) => {
        x.push(
          createData(
            index + 1,
            data.email,
            data.referralCode,
            data.vipCode === true ? 'Used' : 'Unused',
            moment(data.createdAt).format('DD/MM/YYYY')
          )
        );
      });
      setRowData(x);
      setReferralData(x);
    });
  }, [userResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // custom filter by text , referralcode and used & unused
  const handleFilter = (filterForm) => {
    const data = referralData.filter((row) => {
      if (filterForm['vipCode'] && row['vipCode'] !== filterForm['vipCode']) return false;
      if (filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
      if(filterForm['startDate'] && filterForm['endDate']){
        var d1 = filterForm['startDate'].split("/");
        var d2 = filterForm['endDate'].split("/");
        var c = row['createdAt'].split("/");
        var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
        var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
        var check = new Date(c[2], parseInt(c[1])-1, c[0]);
        if(check >= from && check <= to){ return true }else{  return false };
        }
     // if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;	
      return true;
    });
    setRowData(data);
  };
  const resetReferralFilterForm = () => {
    setRowData(referralData);
  };

  const handleSearch = () => {
    setSearchClick(true);
  };
  const handleSubmit = (e) => {
    //e.preventDefault();
    let data = userApi.postReferralCode(email).then((data) => {
      if (data.data) {
        commonService.forSuccess('ReferralCode Created successfully');
	     	window.location.reload();
        setOpen(false);
      } else {
        commonService.forError('email is already exists','error');
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateFormData = (event) => {
    setFormData({ email: event.target.value });
  };
  return (
    <div className="p-3 ">
      <div>
        <h2>
          Referral Code{' '}
          <div className="col-5 float-right">
            <div style={{ display: 'inline', marginRight: '3px' }}>
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={(e) => {
                  handleClickOpen(e);
                }}
              >
                Create Referral Code
              </Button>
            
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                Show Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}
            </div>
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetReferralFilterForm={resetReferralFilterForm}
          usedCodeGroups={['Used','Unused']}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.PostTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">
                              {column.format && typeof value === 'boolean' ? column.format(value) : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25, 50, 100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <div className="row mb-5">
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle style={{ textAlign: 'center' }}>Create Referral Code <span style={{float:'right', cursor:'pointer'}} onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg></span></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <input
                      value={email}
                      onChange={(e) => updateFormData(e)}
                      placeholder="Email address"
                      type="email"
                      name="email"
                      required
                    />
                    <button type="submit">Submit</button>
                    <button onClick={handleClose}>cancel</button>
                  </form>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReferralTable);
