import React from "react";
import { Link }  from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";

import Search from "../../search";

const useStyles = makeStyles(theme => ({
  iconSize: {
    fontSize: 24,
  }
}));

const PrimarySidebar = ({changeTheme}) => {
  const classes = useStyles();

  return (
      <div className="left_sidebar_Content" >
        <div className="left_Logo">
          <Link to="/">
            <img className="w-100" src="https://logoipsum.com/logo/logo-10.svg" alt="logo" />
          </Link>
        </div>
        <div className="top_user">
          <ul className="list-unstyled">
            <li>
              <div className="align-middle custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" onChange={changeTheme} id="customSwitches" />
                <label className="custom-control-label" htmlFor="customSwitches"></label>
              </div>
            </li>
          </ul>
        </div>
      </div>
  )
};

export default PrimarySidebar;

