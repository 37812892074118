import React, { useState, useContext, useEffect } from 'react';
// ui component for page layout
import Header from './components/shared/header';
import Sidebar from './components/shared/sidebar';
import * as userApi from './api';
import AppContext from './context';

import './App.scss';

const App = ({ children }) => {
  const { history } = children.props;
  const [sidebarToggled, setSidebarToggle] = useState(false);
  const [resSidebarToggled, setResSidebarToggle] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('light-theme');
  const { userResponse, setUserResponse } = useContext(AppContext);

  const changeTheme = () => {
    const theme = 'light-theme';
    if (theme !== currentTheme) setCurrentTheme(theme);
  };
  useEffect(() => {
    userApi.getAllUsers().then((data) => {
      setUserResponse(data.data);
    });
  }, [setUserResponse]);

  return (
    <div
      className={`App sidebar-mini rtl ${currentTheme} ${sidebarToggled ? 'sidenav-toggled' : ''} ${
        resSidebarToggled ? 'left_sidebar_visible' : ''
      }`}
    >
      <Header
        history={history}
        sidebarToggled={sidebarToggled}
        resSidebarToggled={resSidebarToggled}
        setSidebarToggle={setSidebarToggle}
        setResSidebarToggle={setResSidebarToggle}
      />
      <Sidebar setResSidebarToggle={setResSidebarToggle} changeTheme={changeTheme} />
      <div className="app-content">
        <main className=" wraper_content edit_caseMainContent">
          <div className="inner_content">
            <div id="content">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default App;
