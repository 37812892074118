import React, { useState, useEffect, useContext } from 'react';
import AppContext from './../../context';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
  makeStyles,
  withStyles,
  GridList,
  GridListTile,
  Drawer,
  Button,
  Divider,
  TextField,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';

// user api
import * as userApi from '../../api';
// service
import * as commonService from '../../utils/CommonService.js';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import CommonForm from '../../components/shared/ui-components/common-form';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const CommunityInfo = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [communityBy, setCommunityBy] = useState();
  const tileData =
    props.userData.eventImage?.length > 0
      ? props.userData.eventImage.map((profilePic) => {
          let rObj = {};
          rObj['img'] = profilePic;
          return rObj;
        })
      : [];
  useEffect(() => {
    const createdBy = userResponse.find((x) => x._id === props.userData.userId);
    if (createdBy) {
      setCommunityBy(createdBy.uniqueName);
    }
  });
  return (
    <>
    <div className="row">
		<div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Community Information</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div>
            <div className="info-line">
              <div className="title">Created By</div>
              <div className="info">{communityBy}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="title">Type</div>
            <div className="info">{props.userData.type}</div>
          </div>
          <div className="info-line">
            <div className="title">Tags</div>
            <div className="info">
              {props.userData.keywords && props.userData.keywords.length
                ? props.userData.keywords.join(', ')
                : 'No Data'}
            </div>
          </div>
        </CardContent>
      </Card>
      </div>
      </div>
      </>
  );
};

export default withRouter(CommunityInfo);
