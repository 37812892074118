import axios from 'axios';

import * as commonService from '../CommonService.js';

export default function errorHandler (error) {
  //hide loader
  commonService.isLoading.onNext(false);
	if (axios.isCancel(error)) {
		return Promise.reject(error);
	}
  if (error.message === "Network Error") {
    commonService.forError('Error connecting server. Please check your internet connection.', 'Error');
    return Promise.reject(error.message);
  }

	switch (error.response.status) {
		case 400:
			if (error.config.defaultErrorHandler) {
        const { message } = error.response.data;
        commonService.forWarning(message, 'Warning');
      }
			break;
		case 401:
			console.log('unauthorized, logging out ...');
			break;
		case 500:
				commonService.forError('Seems like something went wrong!' , 'Error');
			break;
		case 504:
        commonService.forError('Sorry, could not access the external resource to refine the data for your request, please try again later!' , 'Error');
			break;
    default:
      commonService.forError('Seems like something went wrong!' , 'Error');
      break;
	}
	return Promise.reject(error.response);
}
