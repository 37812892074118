import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';

import ReportInfo from './reportInfo';

 const ProfileTabs = (props) => {
   // custom tab data
   const labelsData = [
     'Report Information',
   ];
   const tabsData = {
     0: <ReportInfo userData={props.userData}></ReportInfo>,
   };

  return (
      <TabsUI labels={labelsData} tabs={tabsData} />
  )
};

export default ProfileTabs;