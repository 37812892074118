import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';
import AppContext from './../../context';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const ArticleQst = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);

  return (
    <>
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Question & Answer</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div className="info-line">
            <div className="title">Answer given by</div>
            <div className="info">
              {props.userData['answers'] && props.userData['answers'].length
                ? props.userData['answers'].map((x) => {
                    const found = userResponse.find((data) => data._id === x.userId);
                    if (found) {
                      return <div style={{ display: 'inline' }}>{found.uniqueName},</div>
                    }
                  })
                : 'not available'}
            </div>
          </div>
          <div>
            <div className="info-line">
              <div className="title">Question</div>
              <div className="info">{props.userData?.question}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="title">Options</div>
            <div className="info">
              {props.userData['options'] && props.userData['options'].length
                ? props.userData['options'].map((x) => {
                    return <div style={{ display: 'inline' }}>{x.option},</div>
                  })
                : 'not available'}
            </div>
          </div>
          <div className="info-line">
            <div className="title">Answered by reader</div>
            <div className="info">
              {props.userData.answers && props.userData.answers.length
                ? props.userData.answers.map((x) => {
                    return <div style={{ display: 'inline' }}>{x.answer},</div>;
                  })
                : 'no answer'}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
    </div>
    </>
  );
};

export default withRouter(ArticleQst);
