import React from 'react';

import HeaderMenu from './components/HeaderMenu.js';

import './components/Header.scss';

const Header = ({ resSidebarToggled, setSidebarToggle, setResSidebarToggle, history}) => (
    <header className="app-header" >
      <div className="app-header__logo app_header_block">
        <a href="/">
          <img src={require("../../../assets/images/icon/header_logo.png")} className="sidebar_logo_img" alt="header_logo" />
        </a>
      </div>
      <div className="sidebar_toggle_btn" >
        <span className="app-sidebar__toggle" data-toggle="sidebar" aria-label="Hide Sidebar"> <span className="toogle_left" onClick={() => setSidebarToggle(true)}>
          <i className="toggle_left fa fa-angle-left"></i>
        </span>
          <span className="toogle_right" onClick={() => setSidebarToggle(false)}>
            <i className="toggle_right fa fa-angle-right"></i>
          </span>
        </span>
      </div>
      <h3 className="app_title text-capitalize ml-2">Dating Admin Panel</h3>
      <div className="app-nav-right">
        <div className="mobile_toggle_right">
          <span className="slice-btn" onClick={() => setResSidebarToggle(!resSidebarToggled)} >
            <i className="fa fa-bars" ></i>
          </span>
          <span className="top_heaer_right">
            <i className="ellipsis fa fa-ellipsis-v" ></i>
          </span>
        </div>
        <HeaderMenu history={history} ></HeaderMenu>
      </div>
      <div className="clearfix"></div>
    </header>
);

export default Header;