import React, { useState, useEffect } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as userApi from './../../api';
import * as commonService from '../../utils/CommonService.js';
import Filter from './reportFilter';
import * as moment from 'moment';

const useStyles = makeStyles({
  ReportsTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'by', label: 'Reported By' },
  { id: 'type', label: 'Reported On' },
  // { id: 'typeId', label: 'Name' },
  { id: 'reason', label: 'Reason' },
  { id: 'reportStatus', label: 'Report Status' },
  { id: 'approvedByAdmin', label: 'Status' },
  {
    id: 'createdAt',
    label: 'Created On',
    sortable: true,
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'right',
  },
];

function createData(sno,by, type,createdAt, reason,reportStatus, approvedByAdmin, action) {
  return {
    sno,
    by,
    type,
    reason,
    reportStatus,
     approvedByAdmin,
     createdAt,
    action,
  };
}
const rows = [];

const ReportsTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [reportsData, setReportsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchClick, setSearchClick] = useState(false);
  useEffect(() => {
    let x = [];
    userApi.getAllReports().then((data) => {
      data.data.map((report, index) => {
        x.push(
          createData(
            index + 1,
            report.userId.uniqueName ? report.userId.uniqueName : '-',
            report.type,
            // report.type === 'User'
            // 	? report.typeId.fullName
            // 	: report.type === 'Post'
            // 	? report.typeId.title
            // 	: report.type === 'Event'
            // 	? report.typeId.eventTitle
            // 	: '',
            moment(report.createdAt).format('DD/MM/YYYY'),
            report.reason ? report.reason : '',
            report.reportStatus,  
             report.reportStatus === 'Pending'? (<div><Button style={{ background: '#4dff88' }} onClick={() => ApprovedHandle (report._id)}>
              Approved
           </Button>/<Button style={{ background: '#ff4d4d' }} onClick={() => RejectedHandle(report._id)} >
                Reject
              </Button></div>):report.reportStatus === 'Approved'?<Button style={{ background: '#4dff88' }} onClick={() => RejectedHandle(report._id)}>Approved</Button> : <Button style={{ background: '#ff4d4d' }}onClick={() => ApprovedHandle (report._id)}  >
                Reject
              </Button>,  
            <>
              <VisibilityIcon
                style={{ cursor: 'pointer', align: 'center' }}
                onClick={() => history.push(`/admin/report/${report._id}`)}
              />
           
            </>
          )
        );
      });
      setRowData(x);
      setReportsData(x);
    });
  }, []);

  const ApprovedHandle = (usersData) => {
    if (usersData) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure you want to approve this report ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => handleVerifyConfrm(usersData, value),
      });
    }
  };

  const handleVerifyConfrm = (usersData, value) => {
    if (value === true) {
      const ApprovedStatus = true;
      userApi.postReportStatus(usersData, ApprovedStatus).then((data) => {
        if (data) {
          commonService.isLoading.onNext(true);
          commonService.forSuccess('successfully Approved!', 'Success');
          setTimeout(() => commonService.isLoading.onNext(false), 500);
          commonService.isDialogOpen.onNext(false);
         window.location.reload();
        }
      });
    }else if (value === false){
       window.location.reload();
      commonService.isDialogOpen.onNext(false);
    }
    
  };

  const RejectedHandle = (usersData) => {
    if (usersData) {
      commonService.isDialogOpen.onNext({
        open: true,
        data: {
          message: 'Are you sure you want to reject this report ?',
        },
        cancelText: 'No',
        confirmText: 'Yes',
        onConfirm: (value) => handleRejectConfrm(usersData, value),
      });
    }
  };

  const handleRejectConfrm = (usersData, value) => {
    if (value === true) {
      const RejectedStatus = false;
      commonService.isLoading.onNext(true);
      userApi.postReportStatus(usersData, RejectedStatus).then((data) => {
        if (data) {
          commonService.forWarning('Rejected!', 'Warning');
           window.location.reload();
          commonService.isLoading.onNext(false);
          commonService.isDialogOpen.onNext(false);
        }
      });
    }else if (value === false){
       window.location.reload();
      commonService.isDialogOpen.onNext(false);
    }
    commonService.isDialogOpen.onNext(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // custom filter by text , isPublished and visibility
  const handleFilter = (filterForm) => {
    const data = reportsData.filter((row) => {
      if (filterForm['ReportedOn'] && row['type'] !== filterForm['ReportedOn']) return false;
      if (filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
      if (filterForm['reportStatus'] && row['reportStatus'] !== filterForm['reportStatus']) return false;
      //  if (filterForm['createdAt'] && row['createdAt'] !== filterForm['createdAt']) return false;
       if(filterForm['startDate'] && filterForm['endDate']){
        var d1 = filterForm['startDate'].split("/");
        var d2 = filterForm['endDate'].split("/");
        var c = row['createdAt'].split("/");
        var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
        var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
        var check = new Date(c[2], parseInt(c[1])-1, c[0]);
        if(check >= from && check <= to){ return true }else{  return false };
        }
      return true;
    });
    setRowData(data);
  };
  const resetReportFilterForm = () => {
    setRowData(reportsData);
  };

  const handleSearch = () => {
    setSearchClick(true);
  };

  return (
    <div className="p-3 ">
      <div>
        <h2>
          Reports{' '}
          <div className="col-3 float-right">
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                Show Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetReportFilterForm={resetReportFilterForm}
          reporttypeGroups={[...new Set(reportsData.map((data) => data.type))]}
          reportCreatedOn={[...new Set(reportsData.map((data) => data.createdAt))]}
          reportStatusGroups={['Approved','Rejected','Pending']}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.ReportsTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">
                              {column.format && typeof value === 'boolean' ? column.format(value) : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25, 50, 100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportsTable);
