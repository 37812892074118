import React, { useState, useEffect, useCallback } from 'react';
import { Route, withRouter, useHistory } from 'react-router-dom';

import {
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as userApi from './../../api';
import Filter from './eventFilter.js';
import * as moment from 'moment';
const useStyles = makeStyles({
  EventTableContainer: {
    boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  },
});

const columns = [
  { id: 'sno', label: 'S.No' },
  { id: 'title', label: 'Title' },
  { id: 'createdBy', label: 'Posted by' },
  { id: 'location', label: 'Location' },
  { id: 'eventStartDate', label: 'Event start date', sortable: true },
  { id: 'eventEndDate', label: 'Event end date', sortable: true },
  {
    id: 'tags',
    label: 'Tags',
  },
  {
    id: 'visibility',
    label: 'Posted for',
  },
  {
    id: 'eventRepeat',
    label: 'Repeats in',
  },
  {
    id: 'isPublished',
    label: 'Status',
    align: 'right',
  },
  {
    id: 'created',
    label: 'Created on',
    sortable: true,
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    align: 'right',
  },
];

function createData(
  sno,
  title,
  createdBy,
  description,
  location,
  eventStartDate,
  eventEndDate,
  eventRepeat,
  tags,
  visibility,
  isPublished,
  created,
  action
) {
  return {
    sno,
    title,
    createdBy,
    description,
    location,
    eventStartDate,
    eventEndDate,
    eventRepeat,
    tags,
    visibility,
    isPublished,
    created,
    action,
  };
}
const rows = [];

const EventTable = () => {
  const history = useHistory();
  const classes = useStyles();
  const [eventData, setEventsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchClick, setSearchClick] = useState(false);

  useEffect(() => {
    let x = [];
    userApi.getAllEvents().then((data) => {
      data.data.map((event, index) => {
        x.push(
          createData(
            index + 1,
            event.eventTitle,
            event.userId.uniqueName ? event.userId.uniqueName : '-',
            event.eventDesc,
            event.eventLocation,
            `${moment(event.eventStartDate).format('DD/MM/YYYY')}`,
            `${moment(event.eventEndDate).format('DD/MM/YYYY')}`,
            event.eventRepeat,
            event.eventTags.join(', '),
            event.eventGroup.join(', '),
            event.isPublished ? 'Published' : 'Unpublished',
            moment(event.createdAt).format('DD/MM/YYYY'),
            <>
              <VisibilityIcon
                style={{ cursor: 'pointer', align: 'center' }}
                onClick={() => history.push(`/admin/event/${event._id}`)}
              />
            </>
          )
        );
      });
      setRowData(x);
      setEventsData(x);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // custom filter by text , visibility and visibility
  const handleFilter = (filterForm) => {
    const data = eventData.filter((row) => {
      if (filterForm['isPublished'] && row['isPublished'] !== filterForm['isPublished']) return false;
      if (filterForm['visibility'] && row['visibility'] !== filterForm['visibility']) return false;
      if (filterForm['text'].toLowerCase() && !JSON.stringify(Object.values(row)).toLowerCase().includes(filterForm['text'].toLowerCase())) return false;
      if (filterForm['eventStartDate'] && row['eventStartDate'] !== filterForm['eventStartDate']) return false;
      if (filterForm['eventEndDate'] && row['eventEndDate'] !== filterForm['eventEndDate']) return false;
      // if(filterForm['eventStartDate'] && filterForm['eventEndDate']){
      //   var d1 = filterForm['eventStartDate'].split("/");
      //   var d2 = filterForm['eventEndDate'].split("/");
      //   var c = row['createdAt'].split("/");
      //   var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
      //   var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
      //   var check = new Date(c[2], parseInt(c[1])-1, c[0]);
      //   if(check > from && check < to){ return true }else{  return false };
      //   }
      return true;
    });
    setRowData(data);
  };
  const resetEventForm = () => {
    setRowData(eventData);
  };
  const handleSearch = () => {
    setSearchClick(true);
  };
  const handleFilterDate = useCallback((filterForm) => {
		console.log('filterForm: ', filterForm);
    let x = [];
		 userApi.filterEvents(filterForm).then((data) => {
		data.data.map((event, index) => {
      console.log(event);
			x.push(
				createData(
          event.eventTitle,
          event.userId.uniqueName ? event.userId.uniqueName : '-',
          event.eventDesc,
          event.eventLocation,
          `${moment(event.eventStartDate).format('DD/MM/YYYY')}`,
          `${moment(event.eventEndDate).format('DD/MM/YYYY')}`,
          event.eventRepeat,
          event.eventTags.join(', '),
          event.eventGroup.join(', '),
          event.isPublished ? 'Published' : 'UnPublished',
          moment(event.createdAt).format('DD/MM/YYYY'),
          <>
            <VisibilityIcon
              style={{ cursor: 'pointer', align: 'center' }}
              onClick={() => history.push(`/admin/event/${event._id}`)}
            />
          </>
					
				)
			);
		})});
		setRowData(x);
    setEventsData(x);
	},[]);
  return (
    <div className="p-3 ">
      <div>
        <h2>
          Events{' '}
          <div className="col-3 float-right">
            {searchClick === false ? (
              <Button variant="contained" color="default" className="btn-theme-primary" onClick={() => handleSearch()}>
                 Show Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                className="btn-theme-primary"
                onClick={() => setSearchClick(false)}
              >
                Hide Filter
              </Button>
            )}
          </div>
        </h2>
      </div>
      {searchClick === true ? (
        <Filter
          handleFilter={handleFilter}
          resetEventForm={resetEventForm}
          isPublishedGroups={['Published','UnPublished']}
          eventVisibilityGroups={[...new Set(eventData.map((data) => data.visibility))]}
          eventStartDate={[...new Set(eventData.map((data) => data.eventStartDate))]}
          eventEndDate={[...new Set(eventData.map((data) => data.eventEndDate))]}
          handleFilterDate={handleFilterDate}
        ></Filter>
      ) : (
        ''
      )}
      <div className={`mat-elevation-z8 p-3 ${classes.EventTableContainer}`}>
        <div>
          <Paper className="responsive_table w-100 overflow-auto">
            <Table stickyHeader aria-label="sticky table" className="table-bordered tbl-design">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="text-left"
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align={column.align} className="text-left">
                              {column.format && typeof value === 'boolean' ? column.format(value) : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10,25,50,100]}
              component="div"
              count={rowData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EventTable);
