import React, { useState } from 'react';
// import dropdown icons
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Update from '@material-ui/icons/Update';
import Settings from '@material-ui/icons/Settings';

import * as commonService from '../../../../utils/CommonService.js';

const ProfileDropdown = ({
	StyledMenu,
	StyledMenuItem,
	ListItemText,
	ListItemIcon,
	Button,
	history,
}) => {
	const [profileDropDown, setProfileDropDown] = useState(null);

	const handleProfileClick = (event) => {
		setProfileDropDown(event.currentTarget);
	};

	const handleClose = () => {
		setProfileDropDown(null);
	};

	const handleLogout = (confirm) => {
		if (confirm) {
			commonService.isLoading.onNext(true);
			localStorage.clear();
			commonService.forSuccess('Logout successfully!', 'Success');
			history.push('/admin/login');
			setTimeout(() => commonService.isLoading.onNext(false), 500);
		}
		commonService.isDialogOpen.onNext(false);
	};

	const logout = () => {
		commonService.isDialogOpen.onNext({
			open: true,
			data: {
				message: 'Are you sure you want to Logout?',
			},
			cancelText: 'Cancel',
			confirmText: 'Okay',
			onConfirm: handleLogout,
		});
	};

	const menuItems = [
		{
		 Icon: AccountCircle,
		 handleClick: () => {history.push('/admin/profile')},
		 title: "Profile"
		},
		// {
		//  Icon: Update,
		//  handleClick: () => {},
		//  title: "Update Profile"
		// },
		// {
		//  Icon: Settings,
		//  handleClick: () => {},
		//  title: "Privacy Settings"
		// },
		{
			Icon: KeyboardReturnIcon,
			handleClick: logout,
			title: 'Logout',
		},
	];

	const styleMenu = ({ handleClick, Icon, title, index }) => {
		return (
			<StyledMenuItem key={index}>
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
				<ListItemText primary={title} onClick={() => handleClick()} />
			</StyledMenuItem>
		);
	};

	return (
		<li className='profile-menu'>
			<Button
				aria-controls='customized-menu'
				aria-haspopup='true'
				variant='contained'
				color='primary'
				className='profile-icon p-0 m-0'
				onClick={handleProfileClick}
			>
				<img
					src={require('../../../../assets/images/avatar/Avatar2.png')}
					alt='profile'
				/>
			</Button>
			<StyledMenu
				id='customized-menu'
				anchorEl={profileDropDown}
				keepMounted
				open={Boolean(profileDropDown)}
				onClose={handleClose}
			>
				{menuItems.map((menuItem, index) =>
					styleMenu({ ...menuItem, index })
				)}
			</StyledMenu>
		</li>
	);
};

export default ProfileDropdown;
