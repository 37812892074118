import React from 'react';
import { TextField, FormGroup, Select, MenuItem, InputLabel, FormControl, Divider } from '@material-ui/core';
import CKEditor from 'ckeditor4-react';

const StepContent = (step, handleChange, { generalInfoFormGroup, addressFormGroup }) => {
  switch (step) {
    case 0:
      return (
        <>
          <div className="row">
            <FormGroup className="col-lg-3 col-md-4 col-sm-12">
              <TextField
                variant="outlined"
                label="First Name"
                name="first_name"
                value={generalInfoFormGroup.first_name}
                onChange={(e) => handleChange(generalInfoFormGroup, 'first_name', e.target.value)}
              />
            </FormGroup>
            <FormGroup className="col-lg-3 col-md-4 col-sm-12">
              <TextField
                variant="outlined"
                label="Last Name"
                name="first_name"
                value={generalInfoFormGroup.last_name}
                onChange={(e) => handleChange(generalInfoFormGroup, 'last_name', e.target.value)}
              />
            </FormGroup>
            <FormGroup className="col-lg-3 col-md-4 col-sm-12">
              <FormControl className="w-100 mr-2" variant="outlined">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelWidth={60}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Gender"
                  value={generalInfoFormGroup.gender}
                  onChange={(e) => handleChange(generalInfoFormGroup, 'gender', e.target.value)}
                >
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup className="col-lg-3 col-md-4 col-sm-12">
              <TextField
                variant="outlined"
                label="Phone"
                name="phone"
                value={generalInfoFormGroup.phone}
                onChange={(e) => handleChange(generalInfoFormGroup, 'phone', e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="row mt-3">
            <FormGroup className="col-lg-12 col-sm-12">
              <CKEditor
                config={{
                  toolbarGroups: [
                    { name: 'clipboard', groups: ['clipboard', 'undo'] },
                    { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                    { name: 'links' },
                    { name: 'document', groups: ['mode', 'document', 'doctools'] },
                    { name: 'paragraph', groups: ['list', 'indent', 'center', 'blocks', 'align', 'bidi'] },
                  ],
                }}
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={generalInfoFormGroup.about_me}
                onChange={(e) => {
                  return handleChange(generalInfoFormGroup, 'about_me', e.editor.getData());
                }}
              />
              {/* <TextareaAutosize placeholder="About me" name="about_me" rows={3} className="rounded p-2" value={generalInfoFormGroup.about_me} onChange={(e) => handleChange(generalInfoFormGroup,'about_me',e.target.value)}/> */}
            </FormGroup>
          </div>
        </>
      );
    case 1:
      return (
        <div className="row">
          <FormGroup className="col-lg-3 col-md-4 col-sm-12">
            <TextField
              variant="outlined"
              label="Address"
              name="address"
              value={addressFormGroup.address}
              onChange={(e) => handleChange(addressFormGroup, 'address', e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-lg-3 col-md-4 col-sm-12">
            <TextField
              variant="outlined"
              label="City"
              name="city"
              value={addressFormGroup.city}
              onChange={(e) => handleChange(addressFormGroup, 'city', e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-lg-3 col-md-4 col-sm-12">
            <TextField
              variant="outlined"
              label="State"
              name="state"
              value={addressFormGroup.state}
              onChange={(e) => handleChange(addressFormGroup, 'state', e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-lg-3 col-md-4 col-sm-12">
            <TextField
              variant="outlined"
              label="Zip Code"
              name="zip"
              value={addressFormGroup.zip}
              onChange={(e) => handleChange(addressFormGroup, 'zip', e.target.value)}
            />
          </FormGroup>
        </div>
      );
    case 2:
      return (
        <>
          <div className="pb-2">
            <h5 className="mb-3">Personal Information</h5>
            <div className="row mb-3">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500">First Name</span>
                <p> {generalInfoFormGroup.first_name}</p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500">Last Name </span>
                <p>{generalInfoFormGroup.last_name}</p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500">Gender</span>
                <p>{generalInfoFormGroup.gender}</p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500"> Phone </span>
                <p>{generalInfoFormGroup.phone}</p>
              </div>
              {generalInfoFormGroup.about_me && (
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <span className="font-500"> About Me </span>
                  <div dangerouslySetInnerHTML={{ __html: generalInfoFormGroup.about_me }}></div>
                </div>
              )}
            </div>
          </div>
          <Divider />
          <div className="pt-2">
            <h5>Address</h5>
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500">Address</span>
                <p>{addressFormGroup.address}</p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500">City </span>
                <p>{addressFormGroup.city}</p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500">State</span>
                <p>{addressFormGroup.state}</p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <span className="font-500"> Zip </span>
                <p>{addressFormGroup.zip}</p>
              </div>
            </div>
          </div>
        </>
      );
    default:
      return 'Unknown step';
  }
};

export default StepContent;
