import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

const Community = (props) => {
  return (
    <>
    <div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general ">
        <CardHeader
          className="card-header"
          title={
            <span className="title card-title">
              Community{' '}
              <div className="count-well"
                
              >
                {props.userData.community && props.userData.community.length ? props.userData.community.length : 0}
              </div>
            </span>
          }
        ></CardHeader>
        <div className="height-scroll">
        {props.userData.community && props.userData.community.length ? (
          props.userData.community.map((data) => (
            <CardContent className="row info-line" >
              <div className="col-md-3">
                <img className="profile-image avatar huge" src={data.communityImage} alt="profile" />
              </div>
              <div className="col-md-9">
              <div className='py-1'>
                <div className="title float-left">Name</div>
                <div className="info float-left pl-2">{data.name}</div>
                <div className='clearfix'></div>
                </div>
                <div className='py-1'>
                <div className="title float-left">Members</div>
                <div className="info float-left pl-2">
                  {data.members && data.members.length
                    ? data.members.map((x, index) => {
                        return (
                          <div key={index}>
                            Name: {x.fullName} <div>Level: {x.level} </div>{' '}
                          </div>
                        );
                      })
                    : 'no members'}
                </div>
                <div className='clearfix'></div>
              </div>
              </div>
            </CardContent>
          ))
        ) : (
          <CardContent>No information available yet</CardContent>
        )}
        </div>
      </Card>
    </div>
    </div>
    </>
  );
};

export default Community;
