import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';
import InfluncerInfo from './InfluncerInfo';

const influncerTabs = (props) => {
  // custom tab data
  const labelsData = ['Influencer Information'];
  const tabsData = {
    0: <InfluncerInfo userData={props.userData}></InfluncerInfo>,
  };

  return <TabsUI labels={labelsData} tabs={tabsData} />;
};

export default influncerTabs;
