import React, { useState, useEffect } from 'react';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';

import ProfileTabs from '../../components/report-profile/profileTabs';
import './profile.scss';

const ReportProfile = (props) => {
	const [usersData, setUsersData] = useState([]);

	const toggelActive = (confirm) => {
		if (confirm) {
			userApi.toggelActive(props.match.params.id).then((data) => {
				setUsersData(data.data);
			});
		}
		commonService.isDialogOpen.onNext(false);
	};
	
	useEffect(() => {
		// Update the document title using the browser API
		userApi.getReportDetail(props.match.params.id).then((data) => {
			setUsersData(data.data);
		});
	}, []);

	// const ApprovedHandle=()=>{
	// 		commonService.isDialogOpen.onNext({
	// 		  open: true,
	// 		  data: {
	// 			message: 'Are you sure you want to approve this report',
	// 		  },
	// 		  cancelText: 'No',
	// 		  confirmText: 'Yes',
	// 		  onConfirm:handleVerifyConfrm,
	// 		});
		  
		
	// }
	// const handleVerifyConfrm = (value) =>{
	// 	if(value===true){
	// 	const ApprovedStatus=true;
	// 	userApi.postReportStatus(usersData._id,ApprovedStatus).then((data) => {	
	// 		if(data){
	// 			commonService.isLoading.onNext(true);
	// 			commonService.forSuccess('successfully Approved!', 'Success');
	// 			setTimeout(() => commonService.isLoading.onNext(false), 500);
			
	// 		}
	// 	});
	// }
	// commonService.isDialogOpen.onNext(false);
	// }

	// const RejectedHandle=()=>{
	// 		commonService.isDialogOpen.onNext({
	// 		  open: true,
	// 		  data: {
	// 			message: 'Are you sure you want to reject this report',
	// 		  },
	// 		  cancelText: 'No',
	// 		  confirmText: 'Yes',
	// 		  onConfirm:handleRejectConfrm,
	// 		});
	
	// }

	// const handleRejectConfrm=(value)=>{
	// 	if(value===true){
	// 	const RejectedStatus=false;
	// 	userApi.postReportStatus(usersData._id,RejectedStatus).then((data) => {
	// 		if(data){
	// 			commonService.isLoading.onNext(true);
	// 			commonService. forWarning('Rejected!', 'Warning');
	// 			setTimeout(() => commonService.isLoading.onNext(false), 500);
	// 			}
	// 	});
	// }
	// commonService.isDialogOpen.onNext(false);
	// }
	return (
		<>
			<div className='page-layout simple tabbed' id='profile'>
			<div className='profile-header p-24'style={{position:'relative'}}>
					<div className='button btn-postion'>
						<a className='button-sign back-btn ' href="/admin/reports">Back</a>
					</div>
					<div className='user-info'>
					<h1 style={{color:'white'}}>Report Information</h1>
					</div>
					{/* <div
						className='actions  '
						fxlayout='row'
						fxlayoutalign='end center'
						style={{marginRight:'80px'}}
					>
						<Button
							variant='contained'
							color='default'
							className='btn-theme-primary'
							onClick={ApprovedHandle}
						>
						Approved
						</Button>
						<Button
							variant='contained'
							color='default'
							className='btn-theme-primary'
							onClick={RejectedHandle}
						>
						Rejected
						</Button>
					</div>		 */}
					</div>
				
			</div>
			<div className='content'>
				<ProfileTabs userData={usersData}></ProfileTabs>
			</div>
		</>
	);
};
export default withRouter(ReportProfile);
