import React, { useState, useEffect,useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
	Card,
	CardContent,
	TextField,
	Button,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
} from '@material-ui/core';
import * as moment from 'moment';

const Filter = ({ resetEventForm, handleFilter, eventVisibilityGroups, isPublishedGroups,eventStartDate,eventEndDate}) => {
  const [filterForm, setFilterForm] = useState({
    text: '',
    isPublished: '',
    visibility: '',
    tags: '',
    eventStartDate: '',
    eventEndDate: '',
  });
  const inputTo = useRef(null);
  const inputFrom = useRef(null);
  // reset filter form and data
  const handleReset = () => {
    const data = {
      text: '',
      isPublished: '',
      visibility: '',
      tags: '',
			eventStartDate: '',
			eventEndDate: '',
    };
    inputTo.current.value = '';
    inputFrom.current.value = '';
    setFilterForm(data);
    resetEventForm(data);
  };
  // filter lead table according to filed value changes
  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    handleFilter(data);
  }
  return (
    <div className="row mb-5">
      <div className="col-12">
        <Card>
          <CardContent>
            <form>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <TextField
                    value={filterForm.text}
                    className="w-100 mt-3"
                    onChange={(e) => handleChanges('text', e.target.value)}
                    placeholder="Search"
                  />
                  
                </div>
                
                <div className="col-lg-9 col-md-9 col-sm-12 ">
                  <div className="row">
                  {/* <div className="col-lg-3 col-md-3 col-sm-6">
                  <FormControl >
                    <InputLabel id="demo-simple-select-label">Is Published</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterForm.isPublished}
                      onChange={(e) => handleChanges('isPublished', e.target.value)}
                    >
                      {isPublishedGroups.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div> */}
                  <div className="col-lg-3 col-md-3 col-sm-6">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Posted For</InputLabel>
                    <Select
                      value={filterForm.visibility}
                      onChange={(e) => handleChanges('visibility', e.target.value)}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      {eventVisibilityGroups.map((postVisibility, index) => (
                        <MenuItem key={index} value={postVisibility}>
                          {postVisibility}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                  <FormControl className="MuiInput-underline ">
										{' '}
										<input type="date"
											 className="MuiInputBase-input MuiInput-input mt-3"
                       placeholder='Start Date' 
                      selected={filterForm.eventStartDate}
                      ref={inputTo}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'eventStartDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											} 
										/>
										{/* <TextField
											id='date'
											label='Event Start Date'
											type='date'
											InputLabelProps={{
												shrink: true,
											}}
											onChange={(e) =>
												handleChanges(
													'startDate',
													moment(
														e.target.value
													).toDate()
												)
											}
										/> */}
									</FormControl>
									</div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                  <FormControl className="MuiInput-underline">
										<input
                    type="date"
                    placeholder='End Date' className="MuiInputBase-input MuiInput-input mt-3"
											selected={filterForm.eventEndDate}
											minDate={filterForm.eventStartDate}
                      ref={inputFrom}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'eventEndDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											} 
										/>
									</FormControl>
                  </div>
                </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <Button
                    variant="contained"
                    color="default"
                    className="btn-theme-primary float-none mt-3"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default Filter;
