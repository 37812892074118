import React, { useState, useEffect,useContext} from 'react';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import AppContext from './../../context';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
	makeStyles,
	withStyles,
	GridList,
	GridListTile,
	Drawer,
	Button,
	Divider,
	TextField,
	Card,
	CardContent,
	CardHeader,
	FormGroup,
} from '@material-ui/core';

// user api
import * as userApi from '../../api';
// service
import * as commonService from '../../utils/CommonService.js';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: '100%',
		height: 345,
	},
}));

const ReportInfo = (props) => {
	const classes = useStyles(props);
    const { userResponse, setUserResponse } = useContext(AppContext);
    const [reportByData,setReportByData]=useState();
	const tileData =
		props.userData.profilePics?.length > 0
			? props.userData.profilePics.map((profilePic) => {
					let rObj = {};
					rObj['img'] = profilePic.url;
					return rObj;
			  })
			: [];
   
    useEffect(()=>{
        const reportedBy = userResponse.find((x)=>x._id===props.userData.userId);
        if(reportedBy){
            setReportByData(reportedBy.uniqueName) 
        }

    })

	return (
		<>
		<div className="row">
		<div className="col-lg-6 col-md-6 col-sm-6">
		<Card className='profile-box info-box general' >
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>
							Report Information
						</span>
					}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className='info-line'>
						<div className='title'>Reported By</div>
						<div className='info'>
							{reportByData}
						</div>
					</div>
					<div className='info-line'>
						<div className='title'>Reported on</div>
                        <div className='info'>{props.userData['type']}</div>
						</div>
					<div className='info-line'>
						<div className='title'>Description</div>
						<div className='info'>
                        {props.userData['description']}
						</div>
					</div>
					<div className='info-line'>
						<div className='title'>Reason</div>
						<div className='info'>
                        {props.userData['reason']}
						</div>
					</div>
					
				</CardContent>
			</Card>
			</div>
			</div>
		</>
	);
};

export default withRouter(ReportInfo);
