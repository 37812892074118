import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import AppContext from '../../context';
import { makeStyles, Card, CardContent, CardHeader, FormGroup } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const PartnerInfo = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);

  return (
    <>
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Partners Information</span>}
        ></CardHeader>
        <CardContent className="height-scroll">
          <div>
            <div className="info-line">
              <div className="title">User Name</div>
              <div className="info">
                {userResponse.length
                  ? userResponse.map((x) => {
                      if (x._id === props.userData[0].userId) {
                        return x.uniqueName;
                      }
                    })
                  : '-'}
              </div>
            </div>
          </div>
          <div className="info-line">
            <div className="title">Description</div>
            <div className="info">{props.userData[0]['description']}</div>
          </div>
          <div className="info-line">
            <div className="title">Open to</div>
            <div className="info">
              {props.userData[0]['openTo'] && props.userData[0]['openTo'].length
                ? props.userData[0]['openTo'].map((x) => {
                    return <div>{x}</div>;
                  })
                : 'no data'}
            </div>
          </div>
        </CardContent>
      </Card>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader className="card-header" title={<span className="title card-title">Partners</span>}></CardHeader>
        <div className="height-scroll">
        {props.userData[0].partners && props.userData[0].partners.length
          ? props.userData[0].partners.map((data) => (
              <CardContent className="row info-line">
                <div className="col-md-3">
                  {data.profilePics.map((x) => (
                    <img className="profile-image avatar huge" src={x.url} alt="profile" />
                  ))}
                </div>
                <div className="col-md-9">
                  <div className="title">Name</div>
                  <div className="info">{data['uniqueName']}</div>
                  <div className="title">Level</div>
                  <div className="info">{data['level']}</div>
                  <div className="title">Connections type</div>
                  <div className="info">
                    {data.connections.map((x) => {
                      return <div>{x.connectionType},</div>;
                    })}
                  </div>
                </div>
              </CardContent>
            ))
          : 'No Data available'}
          </div>
      </Card>
      </div>
      </div>
    </>
  );
};

export default withRouter(PartnerInfo);
