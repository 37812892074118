import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';
import CommunityInfo from './CommunityInfo';
import CommunityAdmin from './CommunityAdmin';
import CommunityPost from './CommunityPost';
import CommunityEvent from './CommunityEvent';
import CommunityMemberRequest from './CommunityMemberRequest';
import CommunityRequest from './CommunityMembers';

const ProfileTabs = (props) => {
  // custom tab data
  const labelsData = ['Community Information', 'Admin', 'Post', 'Event', 'Member Request', 'Members'];
  const tabsData = {
    0: <CommunityInfo userData={props.userData}></CommunityInfo>,
    1: <CommunityAdmin userData={props.userData}></CommunityAdmin>,
    2: <CommunityPost userData={props.userData}></CommunityPost>,
    3: <CommunityEvent userData={props.userData}></CommunityEvent>,
    4: <CommunityMemberRequest userData={props.userData}></CommunityMemberRequest>,
    5: <CommunityRequest userData={props.userData}></CommunityRequest>,
  };

  return <TabsUI labels={labelsData} tabs={tabsData} />;
};

export default ProfileTabs;
