import React,{useContext,useState,useEffect} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AppContext from '../../context';
import * as moment from 'moment';

const TravelInfo = (props) => {
	const { userResponse, setUserResponse } = useContext(AppContext);
	const TravelBy = props.userData && props.userData['travelId']!==null?userResponse.find((x)=>x._id===props.userData['travelId'].userId):'';
    return (
		<>
		<div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='profile-box info-box general  '>
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>
							Travel Information
						</span>
					}
				></CardHeader>
				{props.userData['travelId']?(	
				<CardContent className="height-scroll">
					<div>
					<div className='info-line'>
					<div className='py-1 row'>
					<div className='title col-md-4'>Traveller: </div>
					<div className='info col-md-8'>{ TravelBy?.uniqueName?TravelBy?.uniqueName:"data not found"}</div>
					<div className='clearfix'></div>
						</div>
						<div className='py-1 row'>
						<div className='title col-md-4'>TravelCountry: </div>
						<div className='info col-md-8'>{ props.userData && props.userData['travelId']?.travelCountry}</div>
                        <div className='clearfix'></div>
						</div>
						<div className='py-1 row'>
						<div className='title col-md-4'>TravelDate:  </div>
						<div className='info col-md-8'>{moment( props.userData && props.userData['travelId']?.travelDate).format('MM/DD/YYYY')}</div>
						<div className='clearfix'></div>
						</div>
					</div>
					</div>
				
							</CardContent>):<CardContent>No information available yet</CardContent>}
			</Card>
			</div>
			</div>
		</>
	);
};

export default TravelInfo;