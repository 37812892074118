import React from 'react';

import { Validators } from 'react-reactive-form';

import { TextInput } from '../shared/ui-components/common-form/Field.js';

export const fieldConfig = {
  controls: {
    email: {
      options: {
        validators: [Validators.required],
      },
      render: TextInput,
      meta: { label: 'ACCOUNT EMAIL ADDRESS' },
    },
    $field_0: {
      isStatic: false,
      render: ({ invalid, meta: { handleReset } }) => (
        <div>
          <button color="default" className="mt-3 mb-3 w-50 cs_btn_rdus cs_primary" type="submit" disabled={invalid}>
            Send Request
          </button>
        </div>
      ),
    },
  },
};
