import React, { useState, useEffect, useRef } from 'react';
import * as moment from 'moment';
import { Card, CardContent, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';


// import './styles.css';
const Filter = ({ resetGuidelineFilterForm, handleFilter, usedCodeGroups }) => {
 

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    Reference: '',
    question: '',
    answer: '',createdAt:'',startDate:'',endDate:''
  });
  const [filterForm, setFilterForm] = useState({
    text: '',
    description: '',
    Reference: '',
    question: '',
    answer: '',createdAt:'',startDate:'',endDate:''
  });
  // const {
  //   title,
  //   description,
  //   Reference,
  //   question,
  //   answer,
  // } = formData;
 
  const inputTo = useRef(null);
  const inputFrom = useRef(null);

  // reset filter form and data
  const handleReset = () => {
    const data = {
      text: '',
      Status: '',startDate:'',endDate:''
    };
    inputTo.current.value = '';
    inputFrom.current.value = '';
    setFilterForm(data);
    resetGuidelineFilterForm(data);
  };
  // filter lead table according to filed value changes
  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    handleFilter(data);
  };

  
  return (
    <div className="row mb-5">
      <div className="col-12">
        <Card>
          <CardContent>
            <form>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-12">
                <TextField
                    value={filterForm.text}
                    className="w-100 mt-3"
                    onChange={(e) => handleChanges('text', e.target.value)}
                    placeholder="Search"
                  />
                </div>
                
                {/* <div className="col-lg-3 col-md-3 col-sm-12">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterForm.status}
                      onChange={(e) => handleChanges('status', e.target.value)}
                    >
                      {usedCodeGroups.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </div> */}
                  <div className='col-lg-3 col-md-3 col-sm-6'>
                  {/* <FormControl className="MuiInput-underline">
										{' '}
                    <input type="date" onChange={(e) => handleChanges('createdAt', moment(e.target.value).format('DD/MM/YYYY'))} className="MuiInputBase-input MuiInput-input mt-3" />
									</FormControl> */}
                 <FormControl className="MuiInput-underline">
										{' '}
										<input type="date" className="MuiInputBase-input MuiInput-input mt-3"
											placeholder='Date from'
                      selected={filterForm.startDate}
                      ref={inputTo}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'startDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											}
										/>
                    </FormControl>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6'>
                    <FormControl className="MuiInput-underline">
										<input
                    type="date" className="MuiInputBase-input MuiInput-input mt-3"
                    placeholder ='Date to'
											selected={filterForm.endDate}
                      ref={inputFrom}
											//minDate={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
											onChange={(e) =>
												handleChanges(
													'endDate',
													moment(e.target.value).format('DD/MM/YYYY')
												)
											}
										/>
									</FormControl>        
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <Button
                    variant="contained"
                    color="default"
                    className="btn-theme-primary float-none mt-3"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default Filter;
