import React, { useState, useEffect, useContext } from 'react';
import AppContext from './../../context';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
  makeStyles,
  withStyles,
  GridList,
  GridListTile,
  Drawer,
  Button,
  Divider,
  TextField,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
} from '@material-ui/core';

// user api
import * as userApi from '../../api';
// service
import * as commonService from '../../utils/CommonService.js';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import CommonForm from '../../components/shared/ui-components/common-form';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 345,
  },
}));

const CommunityPost = (props) => {
  const classes = useStyles(props);
  const { userResponse, setUserResponse } = useContext(AppContext);
  const [communityBy, setCommunityBy] = useState();
  const tileData =
    props.userData.eventImage?.length > 0
      ? props.userData.eventImage.map((profilePic) => {
          let rObj = {};
          rObj['img'] = profilePic;
          return rObj;
        })
      : [];
  useEffect(() => {
    const createdBy = userResponse.find((x) => x._id === props.userData.userId);
    if (createdBy) {
      setCommunityBy(createdBy.uniqueName);
    }
  });
  return (
    <>
    <div className="row">
		<div className="col-lg-6 col-md-6 col-sm-6">
      <Card className="profile-box info-box general">
        <CardHeader
          className="card-header"
          title={<span className="title card-title">Community Post</span>}
        ></CardHeader>
       {props.userData.posts && props.userData.posts.length?props.userData.posts.map((data)=>
			 <CardContent  className="row info-line">
				 <div className="col-md-3"><img
						className='profile-image avatar huge'
						 src={data.postImage}
						 alt='profile'
						 />
				 </div>
				 <div className="col-md-9">
         <div className='py-1'>
					 <div className='title float-left'>Title</div>
					 <div className='info float-left pl-2'>{data.title}</div>
           </div>
           <div className='clearfix'></div>
           <div className='py-1'>
					 <div className='title float-left'>Description</div>
					 <div className='info float-left pl-2' >{data.postDescription}</div>
           </div>
           <div className='clearfix'></div>
           <div className='py-1'>
					 <div className='title float-left'>User Created Post</div>
					 <div className='info float-left pl-2' >{data.user[0].uniqueName}</div>	
           </div>
           <div className='clearfix'></div>
					 </div>
			 </CardContent>):<CardContent>No information available yet</CardContent>}
      </Card>
      </div>
      </div>
    </>
  );
};

export default withRouter(CommunityPost);
