import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Card, CardContent, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import * as moment from 'moment';
import dateFormat from 'dateformat';
import './styles.css';

const Filter = ({
  resetLeadFilterForm,
  handleFilter,
  leadActiveGroups,
  leadVerifiedGroups,
  leadGenderGroups,
  leadCreatedOn,
}) => {
  const [filterForm, setFilterForm] = useState({
    text: '',
    isActive: '',
    isVerified: '',
    gender: '',
    createdAt: '',
    startDate: '',
    endDate: '',
  });

  const inputTo = useRef(null);
  const inputFrom = useRef(null);
  // reset filter form and data
  const handleReset = () => {
    const data = {
      text: '',
      isActive: '',
      isVerified: '',
      gender: '',
      createdAt: '',
      startDate: '',
      endDate: '',
    };
    inputTo.current.value = '';
    inputFrom.current.value = '';
    setFilterForm(data);
    resetLeadFilterForm(data);
  };
  // filter lead table according to filed value changes
  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    handleFilter(data);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <Card>
          <CardContent>
            <form>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <TextField
                    value={filterForm.text}
                    className="w-100 mt-3"
                    onChange={(e) => handleChanges('text', e.target.value)}
                    placeholder="Search"
                  />
                </div>

                <div className="col-lg-4 col-md-4 col-sm-6">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterForm.gender}
                      onChange={(e) => handleChanges('gender', e.target.value)}
                    >
                      {leadGenderGroups.map((gender, index) => (
                        <MenuItem key={index} value={gender}>
                          {gender}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-4  col-md-4  col-sm-6">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      value={filterForm.isActive}
                      onChange={(e) => handleChanges('isActive', e.target.value)}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      {leadActiveGroups.map((active, index) => (
                        <MenuItem key={index} value={active}>
                          {active}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-4  col-md-4  col-sm-6">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Account Verified</InputLabel>
                    <Select
                      value={filterForm.isVerified}
                      onChange={(e) => handleChanges('isVerified', e.target.value)}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      {leadVerifiedGroups.map((isVerified, index) => (
                        <MenuItem key={index} value={isVerified}>
                          {isVerified}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-4  col-md-4  col-sm-6">
                  {/* <FormControl className='col-lg-12 col-md-12 col-sm-6 MuiInput-underline'>
										{' '}
                    <FormControl className='col-lg-12 col-md-12 col-sm-6 MuiInput-underline'>
										{' '}
                    <input type="date" onChange={(e) => handleChanges('createdAt', moment(e.target.value).format('DD/MM/YYYY'))} className="MuiInputBase-input MuiInput-input" />
									</FormControl>
									</FormControl> */}
                  <FormControl className="MuiInput-underline">
                    {' '}
                    <input
                      type="date"
                      placeholder="Date from"
                      className="mt-3 MuiInputBase-input MuiInput-input"
                      ref={inputTo}
                      selected={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => handleChanges('startDate', moment(e.target.value).format('DD/MM/YYYY'))}
                      
                    />
                  </FormControl>
                </div>
                <div className="col-lg-4  col-md-4  col-sm-6">
                  <FormControl className="MuiInput-underline">
                    <input
                      type="date"
                      placeholder="Date to"
                      className="mt-3 MuiInputBase-input sMuiInput-input"
                      selected={filterForm.endDate}
                      ref={inputFrom}
                      //minDate={filterForm.startDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => handleChanges('endDate', moment(e.target.value).format('DD/MM/YYYY'))}
                    />
                  </FormControl>
                  <div></div>
                </div>
                <div className="col-3 float-right">
                  <Button
                    variant="contained"
                    color="default"
                    className="btn-theme-primary float-none mt-3"
                    onClick={() => handleReset()}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default Filter;
