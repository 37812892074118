import { EmailOutlined } from '@material-ui/icons';
import axios from 'axios';
// temporary user api
export const login = (user) => axios.post('admin/login', user);
export const signup = (user) => axios.post('admin/signup', user);
export const forgotpassword = (user) => axios.post('admin/forgotPassword', user);
export const getAllUsers = () =>
  axios.get('admin/allUsers', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
export const getAllPosts = () =>
  axios.get('admin/allPosts', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
export const getAllEvents = () =>
  axios.get('admin/allEvents', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
export const getAllReports = () =>
  axios.get('admin/getReports', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getAllArticles = () =>
  axios.get('admin/allArticle', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getAllPartners = () =>
  axios.get('admin/allPartnerProfile', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getAllAdminReferral = async () =>{
  return axios.get('admin/getReferralCode', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
}
export const getAllBugs = () =>
  axios.get('admin/allBugs', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getUserDetail = (id) =>
  axios.get(`admin/userAdminDetail/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getPostDetail = (id) =>
  axios.get(`admin/getPostDetail/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getEventDetail = (id) =>
  axios.get(`admin/getEventsId/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getReportDetail = (id) =>
  axios.get(`admin/getReportId/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getBugsDetail = (id) =>
  axios.get(`admin/getBugsDetail/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getArticleDetail = (id) =>
  axios.get(`admin/getArticleId/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getArticlesDetail = (id) =>
  axios.get(`admin/getReportId/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const toggelActive = (id) =>
  axios.post(
    `admin/toggelActive/`,
    { user_id: id },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    }
  );
export const postReferralCode = async (email) => {
  return axios.post(
    `admin/ReferralCode`,
    { email: email },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    }
  );
};
export const changeUserPassword = (password, email) =>
  axios.post(
    `admin/setPassword`,
    { password: password },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    }
  );

export const getAllCommunity = () =>
  axios.get('admin/allCommunity', {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getCommunityById = (id) =>
  axios.get(`admin/getCommunityDetail/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getPartnerById = async(id) =>{
return axios.get(`admin/getPartnerProfile/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
}
export const getInfluncerRecords = (id) =>
  axios.get(`admin/influencerAllRequest`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const getInfluncerById = (id) =>
  axios.get(`admin/influencerId/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });

export const postInfluncerStatus = (_idInfluncer, userid, status) =>
  axios.post(
    `admin/influencerAdmin`,
    { _id: _idInfluncer, userId: userid, status: status },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    }
  );

export const postReportStatus = async (_idreport, status) =>{
 return axios.post(
    `admin/ReportMangementAdmin`,
    { _id: _idreport, status: status },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    }
  );
  }
export const postSelfieVerification = (email, status, urlSelfie) =>
  axios.post(
    `admin/selfieverification`,
    { email: email, status: status, selfieUrl: urlSelfie },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    }
  );

export const postAdminArticle = async (data) => {
  return axios.post(`admin/createAdminArticle`,data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

//count total items
export const countAdminArticle = () => {
  return axios.get(`admin/countArticles`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const countAdminPost = async () => {
  return axios.get(`admin/countPost`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const countAdminEvent = () => {
  return axios.get(`admin/countEvent`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const countAdminCommunties = () => {
  return axios.get(`admin/countCommunities`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const getAdminGuideline = async() => {
  return axios.get(`admin/getAdminArticle`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const postUserStatus = (_iduser,status) => {
  return axios.post(`admin/userStatus`, { _id: _iduser, status:status }, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const deleteArticleById = (id) =>{
 return  axios.delete(`admin/deleteAdminArticle/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
}

export const filterEvents = async(data) =>{
  return axios.post(
		'admin/filterEvents',
		{ ...data },
		{
			headers: {
				authorization: `Bearer ${localStorage.getItem('authToken')}`,
			},
		}
	)
};

export const postBugsStatus = async (_idBugs, status) =>{
  return axios.post(
     `admin/adminBugsManagement`,
     { _id: _idBugs, status: status },
     {
       headers: {
         authorization: `Bearer ${localStorage.getItem('authToken')}`,
       },
     }
   );
   }

   export const adminGuideUpdate = async (article_id,data) =>{
    return axios.post(
       `admin/adminGuideUpdate/${article_id} `,
    data,
       {
         headers: {
           authorization: `Bearer ${localStorage.getItem('authToken')}`,
         },
       }
     );
     }
  
//export const login = (user) => axios.post('admin/login');
