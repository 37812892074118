import React from 'react';

import App from '../App.js';

export const PublicRouterLayout = ({children}) => (
    <div className="app sidebar-mini rtl dark-theme">
      <div className="app-content">
        {children}
      </div>
    </div>
);

export const PrivateRouterLayout = ({children}) => <App>{children}</App>;
