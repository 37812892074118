import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    List,
    Divider,
    ListItem,
    ListItemText,
    ListSubheader,
    IconButton,
    TextField
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './search.scss';

const useStyles = makeStyles({
  list: {
    width: 500,
  },
  fullList: {
    width: 'auto',
  },
});

//temp data for lead
const leads_data = [
  {
    name: 'Leanne Graham',
    updated: new Date('1/1/16')
  },
  {
    name: 'Ervin Howell',
    updated: new Date('1/17/16')
  },
  {
    name: 'Clementine Bauch',
    updated: new Date('1/28/16')
  }
];
//temp data for investigators
const investigators_data = [
  {
    name: 'Patricia Lebsack',
    updated: new Date('2/20/16')
  },
  {
    name: 'Chelsey Dietrich',
    updated: new Date('1/18/16')
  }
];

const SearchSideNav = ({side, toggleDrawer}) => {
  const classes = useStyles();
  const [leads, setLeads] = React.useState(leads_data);
  const [investigators, setInvestigators] = React.useState(investigators_data);

  const filterData = (search) => {
    if (search) {
      setLeads(leads_data.filter(lead => lead.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())));
      setInvestigators(investigators_data.filter(investigator => investigator.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())));
    } else {
      setLeads(leads_data);
      setInvestigators(investigators_data);
    }
  };

  return (
      <div
          className={`drawer-left-sidenav ${classes.list}`}
          role="presentation"
          >
        <div className="row mt-3">
          <div className="col-1 close_section">
            <IconButton color="default" className="back-icon" onClick={toggleDrawer(side, false)} size="small">
              <ArrowBackIcon fontSize="inherit"/>
            </IconButton>
          </div>
          <div className="col-10">
            <TextField placeholder="Search for Leads, Investigators and more" className="w-100" name="search"
                       type="text" onKeyUp={(e) => filterData(e.target.value)}/>

            <List>
              <ListSubheader>
                <strong>Leads</strong>
              </ListSubheader>
              {
                leads.map((lead, index) => {
                  return (
                      <ListItem button key={index} onClick={toggleDrawer(side, false)}>
                        <div className="image-container">
                          <img src={require(`../../../../assets/images/avatar/Avatar${index+1}.png`)} alt="avatar"/></div>
                        <ListItemText primary={lead.name}/>
                      </ListItem>
                  )
                })
              }
              <Divider></Divider>
              <ListSubheader>
                <strong>Investigators</strong>
              </ListSubheader>
              {
                investigators.map((investigator, index) => {
                  return (
                      <ListItem button key={index} onClick={toggleDrawer(side, false)}>
                        <div className="image-container">
                          <img src={require(`../../../../assets/images/avatar/Avatar${index+4}.png`)} alt="avatar"/></div>
                        <ListItemText primary={investigator.name}/>
                      </ListItem>
                  )
                })
              }
            </List>
          </div>
        </div>
      </div>
  )
};

export default SearchSideNav;