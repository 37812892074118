import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Drawer,
	Button,
	Divider,
	TextField,
	FormGroup,
} from '@material-ui/core';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';

import ProfileTabs from '../../components/user-profile/ProfileTabs.js';
import './profile.scss';

const UserProfile = (props) => {
	const [usersData, setUsersData] = useState([]);

	const toggelActive = (confirm) => {
		if (confirm) {
			userApi.toggelActive(props.match.params.id).then((data) => {
				setUsersData(data);
			});
		}
		commonService.isDialogOpen.onNext(false);
	};
	const handelActive = (e, status) => {
		e.preventDefault();
		commonService.isDialogOpen.onNext({
			open: true,
			data: {
				message: `Are you sure you want ${status} user ?`,
			},
			cancelText: 'Cancel',
			confirmText: 'Okay',
			onConfirm: toggelActive,
		});
	};
	useEffect(() => {
		// Update the document title using the browser API
		userApi.getUserDetail(props.match.params.id).then((data) => {
			setUsersData(data);
		});
	}, [setUsersData]);
	return (
		<>
			<div className='page-layout simple tabbed' id='profile'>
			<div className='profile-header p-24'style={{position:'relative'}}>
					<div className='button btn-postion' >
						<a className='button-sign back-btn' href="/admin/users">Back</a>
					</div>
					<div className='user-info'>
					<div className="user-img1">
						{usersData.data && usersData.data.profilePics &&
						usersData.data.profilePics.length > 0 ? (
							<img
								className='profile-image avatar huge'
								src={usersData.data.profilePics[0].url}
								alt='profile'
							/>
						) : (
							<img
								className='profile-image avatar huge'
								src={require('../../assets/images/avatar/undefined.png')}
								alt='profile'
							/>
						)}
						<div className='name'> {usersData.data ?usersData.data.fullName:''} </div>
						<div className='location' > {usersData.data?usersData.data.location:''} <LocationOnIcon
								className='s-16 ml-1 mat-icon notranslate material-icons mat-icon-no-color'
								role='img'
								aria-hidden='true'
							></LocationOnIcon></div>
						</div>
						
					</div>
					
				</div>
			</div>
			<div className='content'>
				<ProfileTabs userData={usersData}></ProfileTabs>
			</div>
		</>
	);
};
export default withRouter(UserProfile);
