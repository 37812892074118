import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Validators } from 'react-reactive-form';
import * as moment from 'moment';
import { TextInput } from '../shared/ui-components/common-form/Field.js';
import {
	makeStyles,
	withStyles,
	GridList,
	GridListTile,
	Drawer,
	Button,
	Divider,
	TextField,
	Card,
	CardContent,
	CardHeader,
	FormGroup,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: '100%',
		height: 345,
	},
}));

const UserInfo = (props) => {
	const classes = useStyles(props);
	const [loginForm, setLoginForm] = useState({});

	const tileData =
	props.userData && props.userData.profilePics?.length > 0
			? props.userData.profilePics.map((profilePic) => {
					let rObj = {};
					rObj['img'] = profilePic.url;
					return rObj;
			  })
			: [];


	return (
		<>
		<div className="row">
			<div className="col-lg-6 col-md-6 col-sm-6">
		<Card className='profile-box info-box general' >
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>
							General Information
						</span>
					}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className='info-line'>
						<div className='title'>Birthday</div>
						<div className='info'>
							{moment(props.userData && props.userData.dob?props.userData.dob:'').format('MM-DD-YYYY')} (
							{moment().diff(moment(props.userData && props.userData.dob?props.userData.dob:''), 'years')}{' '}
							years)
						</div>
					</div>
					<div className='info-line'>
						<div className='title'>Gender:{props.userData && props.userData['sex'].sex? props.userData['sex'].sex:''}</div>
						</div>
					<div className='info-line'>
						<div className='title'>About Me</div>
						<div className='info'>
							{props.userData && props.userData.aboutMe
								? props.userData.aboutMe
								: 'No information available yet'}
						</div>
					</div>
					<div className='info-line'>
						<div className='title'>Level:{props.userData && props.userData['level'] ? props.userData['level']:''}</div>
						</div>
					<div className='info-line'>
						<div className='title'>Karma:{props.userData && props.userData['karma'] ? props.userData['karma']:''}</div>
						</div>
						<div className='info-line'>
						<div className='title'>Moolah:{props.userData && props.userData['moolah'] ? props.userData['moolah']:''}</div>
						</div>
					<div className='info-line'>
						<div className='title'>Gender Identity</div>
						<div className='info'>Female-ness:{props.userData && props.userData['genderIdentity']?.woman['percentage'] ? props.userData['genderIdentity']?.woman['percentage']:''}</div>
						<div className='info'>Male-ness:{props.userData && props.userData['genderIdentity']?.man['percentage'] ? props.userData['genderIdentity']?.man['percentage']:''}</div>
					</div>
					<div className='info-line'>
						<div className='title'>Gender Expression</div>
						<div className='info'>Feminity:{props.userData && props.userData['genderExpression']?.feminity['percentage']? props.userData['genderExpression']?.feminity['percentage']:''}</div>
						<div className='info'>Masculinity:{props.userData && props.userData['genderExpression']?.masculinity['percentage']? props.userData['genderExpression']?.masculinity['percentage']:''}</div>
					</div>
					<div className='info-line'>
						<div className='title'>Personal Sex</div>
						<div className='info'>Male-ness:{props.userData && props.userData['personalSex']?.maleness['percentage']?props.userData['personalSex']?.maleness['percentage']:''}</div>
						<div className='info'>Female-ness:{props.userData && props.userData['personalSex']?.femaleness['percentage']?props.userData['personalSex']?.femaleness['percentage']:''}</div>
					</div>
				</CardContent>
			</Card>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={
						<span className='title card-title'>User Images</span>
					}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className={classes.root}>
						<GridList
							cellHeight={160}
							className={classes.gridList}
							cols={5}
						>
							{tileData.map((tile) => (
								<GridListTile
									key={tile.img}
									cols={tile.cols || 1}
								>
									<img src={tile.img} alt={tile.title} />
								</GridListTile>
							))}
						</GridList>
					</div>
				</CardContent>
			</Card>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6">
			<Card className='mt-3 profile-box info-box general'>
				<CardHeader
					className='card-header'
					title={<span className='title card-title'>Contact</span>}
				></CardHeader>
				<CardContent className="height-scroll">
					<div className='info-line'>
						<div className='title'>Mobile.</div>
						<div className='info  '>
							<span>
								{props.userData && props.userData.contact
									? props.userData.contact
									: 'No information available yet'}
							</span>
						</div>
					</div>

					<div className='info-line'>
						<div className='title'>Emails</div>
						<div className='info  '>
							<span>{props.userData && props.userData.email ?  props.userData.email:''}</span>
						</div>
					</div>
				</CardContent>
			</Card>
			</div>
			</div>
		</>
	);
};

export default withRouter(UserInfo);
