import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, withRouter, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as userApi from './../../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
    width: 150,
  },
  control: {
    padding: theme.spacing(3),
  },
  h4: {
    textAlign: 'center',
    fontSize: 20,
    paddingTop: 20,
  },
  text: {
    textAlign: 'center',
    fontSize: 20,
    color: 'blue',
    paddingTop: 10,
  },
}));

const CardTotal = () => {
  const classes = useStyles();
  const history = useHistory();
  const [postData, setPostData] = useState();
  const [eventData, setEventData] = useState();
  const [communityData, setCommunityData] = useState();
  const [articleData, setArticleData] = useState();

  useEffect(() => {
    userApi
      .countAdminPost()
      .then((data) => setPostData(data.data))
      .catch((err) => console.log(err));
    userApi
      .countAdminEvent()
      .then((data) => setEventData(data.data))
      .catch((err) => console.log(err));
    userApi
      .countAdminCommunties()
      .then((data) => setCommunityData(data.data))
      .catch((err) => console.log(err));
    userApi
      .countAdminArticle()
      .then((data) => setArticleData(data.data))
      .catch((err) => console.log(err));
  }, []);

  const handleClickPost = (e) => {
    e.preventDefault();
    history.push('/admin/posts');
  };

  const handleClickEvent = (e) => {
    e.preventDefault();
    history.push('/admin/events');
  };

  const handleClickCommunity = (e) => {
    e.preventDefault();
    history.push('/admin/community');
  };

  const handleClickArticle = (e) => {
    e.preventDefault();
    history.push('/admin/articles');
  };

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item>
            <a href="" onClick={handleClickPost}>
              {' '}
              <Paper className={classes.paper}>
                <h4 className={classes.h4}>Posts</h4>
                <div className={classes.text}>{postData}</div>
              </Paper>
            </a>
          </Grid>
          <Grid item>
            <a href="" onClick={handleClickEvent}>
              {' '}
              <Paper className={classes.paper}>
                <h4 className={classes.h4}>Events</h4>
                <div className={classes.text}>{eventData}</div>
              </Paper>
            </a>
          </Grid>
          <Grid item>
            <a href="" onClick={handleClickCommunity}>
              <Paper className={classes.paper}>
                <h4 className={classes.h4}>Communities</h4>
                <div className={classes.text}>{communityData}</div>
              </Paper>
            </a>
          </Grid>
          <Grid item>
            <a href="" onClick={handleClickArticle}>
              <Paper className={classes.paper}>
                <h4 className={classes.h4}>Articles</h4>
                <div className={classes.text}>{articleData}</div>
              </Paper>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardTotal;
