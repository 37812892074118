import React from 'react';

import TabsUI from '../shared/ui-components/TabsUI.js';

import PersonalInfo from './UserInfo.js';
import Connection from './Connection.js';
import Partner from './Partner.js';
import Progress from './Progress.js';
import TravelInfo from './TravelInfo.js';
import Community from './community';
import PartnerProfile from './PartnerProfile';

 const ProfileTabs = (props) => {
   // custom tab data
   const labelsData = [
     'Personal Info',
    //  'partner',
     'Connection',
     'Progress',
     'Travel Info',
     'Community',
     'Partner Profile'
   ];
   const tabsData = {
     0: <PersonalInfo userData={props.userData.data}></PersonalInfo>,
    //  1: <Partner userData={props.userData.data}></Partner>,
     1: <Connection userData={props.userData.data}></Connection>,
     2: <Progress userData={props.userData.data}></Progress>,
     3: <TravelInfo userData={props.userData.data}></TravelInfo>,
     4: <Community userData={props.userData} ></Community>,
     5: <PartnerProfile  userData={props.userData}></PartnerProfile>
   };

  return (
      <TabsUI labels={labelsData} tabs={tabsData} />
  )
};

export default ProfileTabs;