import React from 'react';
import { Validators } from 'react-reactive-form';

import { TextInput, CheckBox } from '../shared/ui-components/common-form/Field.js';

export const FieldConfig = {
  controls: {
    email: {
      options: {
        validators: [Validators.required, Validators.email],
      },
      render: TextInput,
      meta: { label: 'Email', type: 'email' },
    },
    password: {
      options: {
        validators: [Validators.required, Validators.minLength(6)],
      },
      render: TextInput,
      meta: { label: 'Password', type: 'password' },
    },
    rememberMe: {
      render: CheckBox,
      meta: { label: 'Keep Me Logged In' },
    },
    $field_0: {
      isStatic: false,
      render: ({ invalid, meta: { handleReset } }) => (
        <div>
          <button color="default" className="mt-3 mb-3 w-50 cs_btn_rdus cs_primary" type="submit" disabled={invalid}>
            Login
          </button>
        </div>
      ),
    },
  },
};
