import React, { useContext } from 'react';

import DashboardPlainCard from './DashboardPlainCard.js';
import StatusBoard from './StatusBoard.js';
import CardTotal from './CardTotal';

const DashboardPage = () => {
  const PlainCardData = [
    { themeTitle: 'primary', color: '#4e73df', title: 'EARNINGS (MONTHLY)', number: '$40,000', icon: 'calendar' },
    { themeTitle: 'success', color: '#1cc88a', title: 'EARNINGS (ANNUAL)', number: '$215,000', icon: 'dollar-sign' },
    { themeTitle: 'info', color: '#36b9cc', title: 'Profile (COMPLETED)', number: '50%', icon: 'clipboard-list' },
    { themeTitle: 'warning', color: '#f6c23e', title: 'PENDING REQUESTS', number: '18', icon: 'comments' },
    { themeTitle: 'danger', color: '#e74a3b', title: '', currency: '' },
    { themeTitle: 'secondary', color: '#858796', title: '', currency: '' },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 pb-3">
          <StatusBoard></StatusBoard>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-3">
          <CardTotal></CardTotal>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
