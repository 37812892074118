import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Drawer,
	Button,
	Divider,
	TextField,
	FormGroup,
} from '@material-ui/core';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import * as userApi from './../../api';
import * as commonService from './../../utils/CommonService.js';

import PartnerTabs from '../../components/partner-profile/PartnerTabs';
import './profile.scss';

const PartnerProfile = (props) => {
	const [usersData, setUsersData] = useState([]);

	const toggelActive = (confirm) => {
		if (confirm) {
			userApi.toggelActive(props.match.params.id).then((data) => {
				setUsersData(data.data);
	
			});
		}
		commonService.isDialogOpen.onNext(false);
	};
	const handelActive = (e, status) => {
		e.preventDefault();
		commonService.isDialogOpen.onNext({
			open: true,
			data: {
				message: `Are you sure you want ${status} user ?`,
			},
			cancelText: 'Cancel',
			confirmText: 'Okay',
			onConfirm: toggelActive,
		});
	};
	useEffect(() => {
		// Update the document title using the browser API
		userApi.getPartnerById(props.match.params.id).then((data) => {
			setUsersData(data.data);
		});
	}, [setUsersData]);
	return (
		<>
			<div className='page-layout simple tabbed' id='profile'>

			<div className='profile-header p-24'style={{position:'relative'}}>
					<div className='button btn-postion'>
						<a className='button-sign back-btn' href="/admin/partners">Back</a>
					</div>
					{usersData && usersData.length ?
					<div className='user-info'>	
						{usersData[0].images &&
						usersData[0].images.length > 0 ? (
							<img
								className='profile-image avatar huge'
								src={usersData[0].images[0]}
								alt='profile'
							/>
						) : (
							<img
								className='profile-image avatar huge'
								src={require('../../assets/images/avatar/undefined.png')}
								alt='profile'
							/>
						)}
						<div className='name'> {usersData[0].name} </div>
					</div>:''}
				</div>
			</div>
			<div className='content'>
			{usersData && usersData.length?<PartnerTabs userData={usersData}></PartnerTabs>:''}
			</div>
		</>
	);
					
};
export default withRouter(PartnerProfile);
