import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@material-ui/core';
import moment from 'moment';

import LineChart from '../shared/ui-components/charts/Line-Chart';
import * as userApi from './../../api';

const StatusBoard = () => {
  const [userData, setUsersData] = useState([{ count: 0, createdAt: 0 }]);
  const data = {
    labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    datasets: [
      {
        label: 'Users',
        lineTension: 0,
        backgroundColor: '#B3E5FC',
        borderColor: '#12B4DE',
        borderWidth: 2.5,
        data: userData.map((a) => a.count),
      },
    ],
  };
  useEffect(() => {
    userApi.getAllUsers().then((data) => {
      let arr = data.data.reverse();
      let distict_dates = [...new Set(arr.map((a) => a.createdAt.substring(0, 10)))];
      let graphdata = distict_dates.map((a) => ({
        count: arr.filter((a1) => a1.createdAt.startsWith(a)).length,
        createdAt: a,
      }));
      setUsersData(graphdata);
    });
  }, []);

  return (
    <div>
      <Card className={'card p-3'}>
        <h4 style={{ textAlign: 'center' }}>Users</h4>
        <CardContent>
          <LineChart
            height={40}
            data={data}
            options={{
              maintainAspectRatio: true,
              legend: {
                display: true,
                position: 'top',
              },
              scales: {
                yAxes: [
                  {
                    display: false,
                  },
                ],
              },
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};
export default StatusBoard;
