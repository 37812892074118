import React from 'react';
import {
	makeStyles,
	Card,
	Stepper,
	Step,
	StepLabel,
	Button,
} from '@material-ui/core';

import * as commonService from '../../../utils/CommonService.js';

import StepContent from './../../../components/clients/new-client/StepContent.js';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

function getSteps() {
	return ['Personal Info', 'Address', 'Preview'];
}

const NewClient = ({ history }) => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const [clientForm, setClientForm] = React.useState({
		generalInfoFormGroup: {
			first_name: '',
			last_name: '',
			gender: '',
			phone: '',
			about_me: '',
		},
		addressFormGroup: {
			address: '',
			city: '',
			state: '',
			zip: '',
		},
	});

	const steps = getSteps();

	const handleNext = () => {
		if (activeStep === 2) {
			commonService.forSuccess('Client added successfully!', 'Success');
			history.push('/admin/clients');
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleChange = (group, key, value) => {
		group[key] = value;
		setClientForm({ group, ...clientForm });
	};

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<div>
				<form>
					<Card className='p-3'>
						<div>
							<div
								className={`container ${classes.instructions}`}
							>
								{StepContent(
									activeStep,
									handleChange,
									clientForm
								)}
							</div>
							<div>
								<Button
									disabled={activeStep === 0}
									onClick={handleBack}
									className={classes.button}
								>
									Back
								</Button>
								<Button
									variant='contained'
									color='primary'
									type='button'
									onClick={handleNext}
									className={classes.button}
								>
									{activeStep === steps.length - 1
										? 'Finish'
										: 'Next'}
								</Button>
							</div>
						</div>
					</Card>
				</form>
			</div>
		</div>
	);
};

export default NewClient;
