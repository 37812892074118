import React, { useContext, useState, useEffect } from 'react';

import SideMenu from './components/SideMenu';
import PrimarySidebar from './components/PrimarySidebar';
import AppContext from './../../../context';

/*
 all links contained in sidebar
 */
const links = [
  {
    link: '/admin/dashboard',
    title: 'Dashboard',
    exact: true,
    icon: 'icn_dashboard',
  },

  {
    link: '/admin/users',
    title: 'Users',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/posts',
    title: 'Posts',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/events',
    title: 'Events',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/reports',
    title: 'Reports',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/community',
    title: 'Community',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/articles',
    title: 'Articles',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/partners',
    title: 'Partners profile',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/Bug',
    title: 'Bugs & Features',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/ReferralCode',
    title: 'Referral Code',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/Influncer',
    title: 'Influencer management',
    exact: false,
    icon: 'icn_user',
  },
  {
    link: '/admin/Guideline',
    title: 'Community guidelines',
    exact: false,
    icon: 'icn_user',
  },
];
/*
 * params set sidebar toggle and responsive sidebar toggle
 * */
const Sidebar = ({ setResSidebarToggle, ...props }) => {
  const { adminProfile, setAdminProfile } = useContext(AppContext);
  const [adminData, setAdminData] = useState(
    JSON.parse(window.localStorage.getItem('adminDetail'))
  );

  return (
    <section className="left_sidebar_wrapper">
      <div className="cross-icon" onClick={() => setResSidebarToggle(false)}>
        <span className="fa fa-close"></span>
      </div>
      <div className="left_Sidebar_Sec">
        <PrimarySidebar {...props} />
        <aside className="app-sidebar">
          <div className="app-sidebar_Heading">
            <h4>
              {adminData.firstName}
              {adminData.lastName}
            </h4>
          </div>
          <ul className="app-menu">
            {links.map((link, index) => (
              <SideMenu key={index} {...link} />
            ))}
          </ul>
        </aside>
      </div>
    </section>
  );
};

export default Sidebar;
